import React from 'react'
import { Row } from 'react-bootstrap';

import './aifprinciples.css';

import himg1 from '../images/Capabilities-value.webp';
import himg2 from '../images/Processes-value.webp';
import himg3 from '../images/People-value.webp';

const AIFPrinciples = () => {
  
  return (
    <section className='aipfrinciples-section'>
        <div className="container py-5">
            <div className="bl-green pt-0 pb-3">
                <h2><span>AI-First</span> Principles</h2>
                <div className="text-box">
                    <p>AI & Generative AI technology is changing every industry including traditional software development and technology integration services. ConceptVines seeks to be authentic in its approach to applying a Consulting and Services mindset that is leveraging AI extensively in our business model. From infusing Co-Pilot technology as an integral part of our Engineering methodology to helping our clients transform their own engineering organizations as well as their core business processes and talent. Effectively moving the traditional labor-arbitrage model to technology-arbitrage to generate more speed and value in our deliverables.</p>
                </div>
            </div>


            <Row className="anima-boxes g-5">
                <div className="col-md-4">
                    <div className='anima-box'>
                        <div className='img-box'>
                            <img src={himg1} alt=''/>
                        </div>
                        <div className="text-box green">
                            <h3>Capabilities</h3>
                            <h4>We leverage our proprietary AI-trend tracker to proactively ide...</h4>
                            <p>We leverage our proprietary AI-trend tracker to proactively identify untapped opportunities and strategically invest in startups to address customer challenges. </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                <div className='anima-box'>
                        <div className='img-box'>
                            <img src={himg2} alt=''/>
                        </div>
                        <div className="text-box blue">
                            <h3>Processes</h3>
                            <h4>We turbocharge our delivery process with cutting-edge gen...</h4>
                            <p>We turbocharge our delivery process with cutting-edge generative AI-based commercial tools and SaaS platforms to strategically reduce costs and continually invest in research and development.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                <div className='anima-box'>
                        <div className='img-box'>
                            <img src={himg3} alt=''/>
                        </div>
                        <div className="text-box yellow">
                            <h3>People</h3>
                            <h4>We deploy an AI-first approach to effortlessly source and train t...</h4>
                            <p>We deploy an AI-first approach to effortlessly source and train top talent in advanced technologies, bringing in unparalleled efficiency in workforce development.</p>
                        </div>
                    </div>
                </div>

            </Row>

        </div>
        
    </section>

  )
}

export default AIFPrinciples
