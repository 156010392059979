import React from 'react'
import Menu from '../components/Menu'
import MainSlider from '../components/MainSlider'
import HomeWhoWR from '../components/HomeWhoWR'
import HomeCatapulating from '../components/HomeCatapulating'
import HomeDisruptors from '../components/HomeDisruptors'
// import HomeReadiness from '../components/HomeReadiness'
import HomeContact from '../components/HomeContact'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <>
    <Helmet>
      <title>Venture Engineering | ConceptVines</title>
      <meta property="og:title" content="Venture Engineering | ConceptVines" />
      <meta name='og:description' content='ConceptVines is a global innovation company focused on business growth through disruptive technologies. Our company has two distinct but tightly integrated purposes. First is our Venture Capital business, ConceptVines Ventures, it is focused on investing in early-stage disruptive technology companies.'/>
      {/* <meta name='og:description' content='ConceptVines offers Consulting and Product Engineering Services focused on disruptive technologies & fueled by progressive startups.'/> */}
      <meta name='keywords' content=''/>
    </Helmet>

    <div>
        <Menu />
        <MainSlider />
        <HomeWhoWR />
        <HomeCatapulating />
        <HomeDisruptors />
        {/* <HomeReadiness /> */}
        <HomeContact />
        <Footer />
    </div>
    </>
  )
}

export default Home
