import React from 'react'
import './aboutsection.css';

const AboutSection = (props) => {
  return (
<>
    <section className={`about-ca-section ${props.class}`}>
        <div className='container'>
            <div className='row align-items-center  g-5'>
                <div className='col-md-8 order-m-1'>
                    <div className='bl-green'>
                    <div className='text-box' dangerouslySetInnerHTML={{__html: props.text}} >
                        {/* <p>We <span>simplify</span> and expedite your journey to cloud-native excellence through a unique combination of <span>industry-specific</span> modernization playbooks and outcome-driven implementation programs in our advanced technology center. </p>
                        <p>From managing infrastructure as code to conquering complexity in containerization, from scalability to ensuring flexibility between micro and monolithic systems, we make <span>cloud modernization</span> seamless. During the process, we ensure that cost is not a factor that affects you.</p> */}
                    </div>
                    </div>
                </div>
                <div className='col-md-4 text-center'>
                    <img src={props.img} alt='' />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default AboutSection
