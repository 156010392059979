import React, { useState } from 'react'
import brandlogo from "../images/Brand Logo.png";
import brandlogomb from "../images/brand-logo-mb.webp";
import Container from 'react-bootstrap/Container';
//import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Outlet, NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useParams } from 'react-router-dom';



import './menu.css';

const Menu = () => {
    let { id } = useParams();  
    let expand = 'md';
    let homeurl = "/";

    const [fix, setFix] = useState(false);

    const [show, setShow] = useState(false);
    const [showwwr, setShowwwr] = useState(false);

    // const [showcareer, setShowcareer] = useState(false);

    // const [service, setService] = useState('');
    // const [career, setCareer] = useState('');


    // const [activecareer, setActivecareer] = useState('');
    // const [activeservice, setActiveservice] = useState('');

    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }


    const showDropdownWwr = (e)=>{
        setShowwwr(!showwwr);
    }
    const hideDropdownWwr = e => {
        setShowwwr(false);
    }

    // const showCareerDropdown = (e)=>{
    //     setShowcareer(!showcareer);
    // }
    // const hideCareerDropdown = e => {
    //     setShowcareer(false);
    // }


    // const tolifeps = ()=> {
    //     setBlank(true);
    //    setTimeout(() => {
    //         const lifeps = document.getElementById('lifeps');
    //         window.scrollTo(0, lifeps.offsetTop);  
    //         setBlank(false);      
    //    }, 100);
    // }

    // const tojobopening = ()=> {
    //     setBlank(true);
    //     setTimeout(() => {            
    //         const jobopening = document.getElementById('jobopening');
    //         window.scrollTo(0, jobopening.offsetTop);
    //         setBlank(false);
    //     }, 100);
    // }
    

    const scrollToTop = () => {
        // setBlank(true);
        setTimeout(() => {
        window.scrollTo(0, 0);
        }, 100);
        setTimeout(() => {
        // setBlank(false);            
        }, 600);
    }

    // const toService = ()=> {
    //     navigate("/service");
    //     setActiveservice('activedropdown');
    // }

    // const tocareer = ()=> {
    //     navigate("/careers");
    //     setActivecareer('activedropdown');
    // }


    // useEffect(()=>{
        
    //     const el = document.getElementById("offcanvasNavbarDropdown-expand-md");
    //     el.addEventListener("click", ()=>{
    //         toService();
    //     }, false);
    
    //     const e2 = document.getElementById("offcanvasNavbarCareerDropdown-expand-md");
    //     e2.addEventListener("click", ()=>{
    //         tocareer();
    //     }, false);
  
        
    // },[activecareer,activeservice,navigate]);
    

    
    function setFixed() {
        // console.log(window.screenY);
        if(window.screenY >= 100) {
            setFix(true);
        } else {
            setFix(false);
        }
    }

    window.addEventListener("scroll",setFixed);
    

    window.addEventListener('scroll', function() {
        let scrollPosition = window.scrollY;
        // Use the scrollPosition as needed
        // console.log(scrollPosition);
        if (scrollPosition > 100) {
            setFix(true);
        } else {
            setFix(false);
        }
    });
    

    // useEffect(() => {
    //     function setFixed() {
    //         if(window.screenY >= 100) {
    //             setFix(true);
    //         } else {
    //             setFix(false);
    //         }
    //     }
    
    //     window.addEventListener("scroll",setFixed);
        
    //     return () => {
    //       window.removeEventListener('scroll', setFixed);
    //     };
    //   }, []);


    const [showc, setShowc] = useState(false);

    const handleClose = () => setShowc(false);
    const handleShow = () => setShowc(true);

  return (
    <>
    <div className={(fix) ? "menu fixed" : "menu"}>
        <Container fluid className='hero px-5 pt-3'>   
            <Navbar key={expand} bg="transparent"  expand={expand} className="navbar-dark  ps-3 pe-5 mb-3">
                <Navbar.Brand href={homeurl}>
                    <img className='desktop tablet' src={id !== "" ? brandlogo : brandlogo } alt='Concept Vines' />
                    <img className='mobile' src={id !== "" ? brandlogomb : brandlogomb } alt='Concept Vines' />
                </Navbar.Brand>
                <Navbar.Toggle onClick={handleShow} aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas
                show={false}
                onHide={handleClose} 
                onshow={handleShow}
                id={`offcanvasNavbar-expand-${expand}`}
                className={(showc) ? 'offcanvas offcanvas-end show' : 'offcanvas offcanvas-end'}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                >
                    <Offcanvas.Header>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <NavLink to="/"><img src={id !== "" ? brandlogo : brandlogo }  alt="Logo" /></NavLink>
                        </Offcanvas.Title>
                        <button type="button" onClick={handleClose} className="btn-close" aria-label="Close"></button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
              
                        <Nav className="justify-content-end flex-grow-1 pe-3">
              
                            {/* <Link href="about">About Us</Link>
                            <Link href="service">Services</Link>
                            <Link href="whowr">Who We Serve</Link>
                            <Link href="knowledge">Knowledge</Link>
                            <Link href="career">Careers</Link>
                            <Link href="contant">Contact Us</Link> 
  
                            */}


                            {/* <NavLink className="nav-link" to="/who-we-are" onClick={scrollToTop} >Who we are</NavLink> */}
                            
                            <NavDropdown 
                                className={`services desktop tablet`}
                                // className={`services ${activeservice} desktop`}
                                // title="Services "
                                title={<NavLink to="/who-we-are" onClick={scrollToTop} >Who we are</NavLink>}
                                // onToggle={() => { window.location.href = '/service'}}
                                id={`offcanvasNavbarDropdown-service-expand-${expand}`}
                                show={showwwr}
                                onMouseEnter={showDropdownWwr} 
                                onMouseLeave={hideDropdownWwr}  
                            >
                                {/* <NavLink to="/who-we-are" onClick={scrollToTop}  className="dropdown-item">About Us</NavLink> */}
                                <HashLink smooth  to="/who-we-are/#aboutus"  className="dropdown-item">About Us</HashLink>
                                <HashLink smooth  to="/who-we-are/#ourvalues"  className="dropdown-item">Our Values</HashLink>
                                <HashLink smooth  to="/who-we-are/#ourteam"  className="dropdown-item">Our Team</HashLink>
                                <HashLink smooth  to="/who-we-are/#atechpartners"  className="dropdown-item">Advanced Technology Partners</HashLink>
                               
                            </NavDropdown>

                            <NavDropdown 
                                // className={`services`}
                                className={`services  mobile`}
                                // title="Services "
                                title={<NavLink to="/who-we-are" onClick={scrollToTop} >Who we are</NavLink>}
                                // onToggle={() => { window.location.href = '/service'}}
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                // show={showwwr}
                                // onClick={hideDropdownWwr}
                                // onToggle={hideDropdownWwr}
                                // onMouseEnter={showDropdownWwr} 
                                // onMouseLeave={hideDropdownWwr}  
                            >
                                {/* <NavLink to="/who-we-are" onClick={scrollToTop}  className="dropdown-item">About Us</NavLink> */}
                                <HashLink smooth onClick={handleClose}  to="/who-we-are/#aboutus"  className="dropdown-item">About Us</HashLink>
                                <HashLink smooth onClick={handleClose}  to="/who-we-are/#ourvalues"  className="dropdown-item">Our Values</HashLink>
                                <HashLink smooth onClick={handleClose} to="/who-we-are/#ourteam"  className="dropdown-item">Our Team</HashLink>
                                <HashLink smooth onClick={handleClose} to="/who-we-are/#atechpartners"  className="dropdown-item">Advanced Technology Partners</HashLink>
                               
                            </NavDropdown>

                            <NavDropdown 
                                className={`services desktop tablet`}
                                // className={`services ${activeservice} mobile`}
                                // title="Services "
                                title={<NavLink to="/what-we-do" onClick={scrollToTop} >What we do</NavLink>}
                                // onToggle={() => { window.location.href = '/service'}}
                                id={`offcanvasNavbarDropdown-expand-${expand}`}
                                show={show}
                                onMouseEnter={showDropdown} 
                                onMouseLeave={hideDropdown}  
                            >
                                <NavLink to="/cloud-and-application" onClick={scrollToTop}  className="dropdown-item">Cloud & Applications</NavLink>
                                <NavLink to="/data-and-ai" onClick={scrollToTop}  className="dropdown-item">Data & Artificial Intelligence</NavLink>
                                <NavLink to="/digital-innovation" onClick={scrollToTop}  className="dropdown-item">Digital Innovation</NavLink>
                                <NavLink to="/intelligent-automation" onClick={scrollToTop}  className="dropdown-item">Intelligent Automation</NavLink>
                               
                            </NavDropdown>
                            
                            <NavDropdown 
                                className={`services mobile`}
                                // className={`services ${activeservice} mobile`}
                                // title="Services "
                                title={<NavLink to="/what-we-do" onClick={scrollToTop} >What we do</NavLink>}
                                // onToggle={() => { window.location.href = '/service'}}
                                id={`offcanvasNavbarDropdown-whatwedo-expand-${expand}`}
                                // show={show}
                                // onMouseEnter={showDropdown} 
                                // onMouseLeave={hideDropdown}  
                            >
                                <NavLink to="/cloud-and-application" onClick={scrollToTop}  className="dropdown-item">Cloud & Applications</NavLink>
                                <NavLink to="/data-and-ai" onClick={scrollToTop}  className="dropdown-item">Data & Artificial Intelligence</NavLink>
                                <NavLink to="/digital-innovation" onClick={scrollToTop}  className="dropdown-item">Digital Innovation</NavLink>
                                <NavLink to="/intelligent-automation" onClick={scrollToTop}  className="dropdown-item">Intelligent Automation</NavLink>
                               
                            </NavDropdown>


                            <NavLink className="nav-link" to="/ventures" onClick={scrollToTop} >Ventures</NavLink>
                             {/*<NavLink className="nav-link" to="/latest-insights" onClick={scrollToTop} >Latest Insights</NavLink>*/}
                            <NavLink className="nav-link" to="/join-us" onClick={scrollToTop} >Join Us</NavLink>
                            <NavLink className="nav-link" to="/contact-us" onClick={scrollToTop} >Contact Us</NavLink>
                                
                            {/* <div className={`services  nav-item ${service} dropdown desktop`}>
                                <div id="offcanvasNavbarDropdown-expand-md" aria-expanded="true" role="button" className="dropdown-toggle show nav-link" tabIndex="0">
                                 <NavLink to="/service" onClick={scrollToTop} >Services</NavLink>
                                </div>
                                <div
                                 onMouseEnter={()=>{setService('show')}} 
                                 onMouseLeave={()=>{setService('')}} 
                                aria-labelledby="offcanvasNavbarDropdown-expand-md" data-bs-popper="static" className={`dropdown-menu  ${service}`}>

                                <NavLink to="/managementconsulting" onClick={scrollToTop}  className="dropdown-item" >Management Consulting</NavLink>
                                <NavLink to="/investmentadvisory" onClick={scrollToTop}  className="dropdown-item">Investment Advisory</NavLink>
                                <NavLink to="/businessanalytics" onClick={scrollToTop}  className="dropdown-item">Business Analytics</NavLink>
                                    </div>
                                </div> */}
                                                     
                        </Nav>
                          
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </Container> 
    </div>
    <Outlet />
    </>
  )
}

export default Menu