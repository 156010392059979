import React, {useState, useEffect} from 'react'
import './nextchapter.css'
import { Row } from 'react-bootstrap'
import Position from './Position';

const NextChapter = () => {
      const [activeTab, setActiveTab] = useState("all");
      const [tabs, setTabs] = useState([]);
      const [cat, setCat] = useState('');


    //   const [Tab1, setTab1] = useState(false);
    //   const [Tab2, setTab2] = useState(false);
    //   const [Tab3, setTab3] = useState(false);
    //   const [Tab4, setTab4] = useState(false);
    //   const [Tab5, setTab5] = useState(false);
    //   const [Tab6, setTab6] = useState(false);
    //   const [Tab7, setTab7] = useState(false);

      // const viewbutton = document.querySelector(".view-more");

        // // Add a click event listener to the button
        // viewbutton.addEventListener("click", function () {
        // // Hide the unordered list
        //     console.log(viewbutton.parentNode.nodeName);
        //     // viewbutton.parentElement().nodeName;
        // });


        // var elements = document.getElementsByClassName("classname");
        // for (var i = 0; i < elements.length; i++) {
        //     elements[i].addEventListener('click', myFunction, false);
        // }


        const [posts, setPosts] = useState([]);
        // const [page, setPage] = useState(1);
        // const [total, setTotal] = useState(0);
        
        // // const [postcount, setPostcount] = useState(1);
        
        // const postPerPage = 9;
        
        // // const pagesVisited = page * usersPerPage;
        // const pageCount = Math.ceil(total / postPerPage);
        // const changePage = ({ selected }) => {
        //     // setPageNumber(selected);
        //     // scrollTop();
        //     setPage(selected+1);
        // };
    
    
        useEffect(() => {

            fetch('https://blogs.conceptvines.com/wp-json/position/v1/cat', { method: "GET"})
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setTabs(data);
                // let tc = 0;
                // tabs.map((tab)=> {
                //     tc = tc + tab.count;
                // })
                // setTotal(total);
                
            });
    


            // fetch('https://sandboxmachine.com/blog-conceptvines/wp-json/position/v1/job_posts?cat='+cat+'&&page='+page, { method: "GET"})
            fetch('https://blogs.conceptvines.com/wp-json/position/v1/job_posts?cat='+cat, { method: "GET"})
            .then(response => response.json())
            .then(data => {
                // console.log(data)
                setPosts(data);
                });
    
        }, [cat]);
    

  return (
    <>
    <section className="nextchapter-section">
        <div className="container">
            <div className="bl-green">
                    <h2 className='text-box desktop tablet'>
                    Your Next Chapter: A career at<br/><span>ConceptVines</span>  
                    </h2>

                    <h2 className='text-box mobile'>
                    Your Next Chapter: A career at <span>ConceptVines</span>  
                    </h2>
            </div>

            <Row className='mt-5 pt-0 pt-lg-5'>
                <div className='col-md-3'>
                    <div className='left-side'>
                        <div className="tabs">
                        
                        <div className={activeTab === 'all' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('all'); setCat('');  }} >All positions ({tabs.reduce((total, item) => total + item.count, 0)})</div>                         
                        
                        {tabs.map((tab, index)=> {
                         return (
                            <>
                                <div key={index} className={activeTab === tab.slug ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab(tab.slug); setCat(tab.slug); }} >{tab.name} ({tab.count})</div>                         
                            </>
                         )   
                        })
                    }
                
                            {/* <div className={activeTab === 'tab2' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab2'); setCat('engineering'); }} >Engineering (1)</div>                         
                            <div className={activeTab === 'tab3' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab3'); setCat('architect'); }} >Architect (1)</div>                         
                            <div className={activeTab === 'tab4' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab4'); setCat('design'); }} >Design (1)</div>                         
                            <div className={activeTab === 'tab5' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab5'); setCat('operation'); }} >Operation (1)</div>                         
                            <div className={activeTab === 'tab6' ? "tablinks active" : "tablinks"} onClick={() => { setActiveTab('tab6'); setCat('marketing'); }} >Marketing (1)</div>                          */}
                        </div>

                        <p className='desktop'>
                            {/* We are always seeking talented people. In case you cannot find your desired position here, please send us your LinkedIn profile and give us your contact information. We will be in touch.  */}
                            We are always seeking talented people. In case you cannot find your desired position here, you can directly email us with your resume at <a style={{display:'inline-block'}} href="mailto:careers@conceptvines.com">careers@conceptvines.com</a> 
                        
                        </p>
                    </div>
                </div>

                <div className='col-md-9'>
                    <div className='right-side'>
                    
                    {posts.map((post, index)=> {
                         return (
                            <>
                            <Position key={index} ID={post.job_id} post_title={post.post_title} 
                            experience={post.experience} location={post.location} 
                            short_description={post.short_description} 
                            post_content={post.post_content} />
                            
                             {/* <div className='position' key={index}>
                                <h3>{post.post_title}</h3>
                                <ul className='tag'>
                                    <li><span>JD ID:</span> {post.ID}</li>
                                    <li><span>Experience Level:</span> {post.experience}</li>        
                                    <li><span>Location:</span> {post.location}</li>        
                                </ul>
                                
                                <p>{post.short_description}Lead and facilitate agile development processes and ensure our development teams follow agile best practices. The ideal candidate should understand Scrum, Kanban, and other agile methodologies and have experience leading cross-functional development teams.</p>
                                <div className={(Tab1 === true) ? "more-content show" : "more-content "} dangerouslySetInnerHTML={{__html:post.post_content}} >
                                </div>
                                {(Tab1 !== true) ? <div className='view-more' onClick={()=>{setTab1(true)}}>View Detail Requirement</div> : <div className='view-more' onClick={()=>{setTab1(false)}}>View Less</div>}
                                
                            </div> */}
                            
                            </>
                         )   
                        })
                    }
                    
                
                   </div> 
                </div>

            </Row>
        </div>
    </section>
    </>
  )
}

export default NextChapter
