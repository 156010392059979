import React from 'react'
import { Row } from 'react-bootstrap'
import { HashLink } from 'react-router-hash-link'
import './tomorrowschallenges.css'

const TomorrowsChallenges = () => {
    // const preventDefault = (e) => {
    //     e.preventDefault();
    //     e.stopPropagation(); // Prevent event propagation
    //     // Do whatever you need here
    //   };
  return (
    <>
    <section className="tomorrowschallenges-section">
        <div className="container">
            <div className="main-box">
                    <h1>                        
                    Solutions for <span>tomorrow's</span> challenges  
                    </h1>
            </div>
        </div>

        <div className="container-fluid desktop px-0 mx-0">
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                        <div className="bg-1"></div>
                    {/* <img src={img1} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5">
                        <h3>Product Ideation</h3>
                        <h4>Transform ideas into breakthroughs</h4>
                        
                        <p>We leverage AI and deep industry insights to streamline ideation, identify viable innovations, and transform abstract ideas into actionable project plans, setting the stage for successful development and launch.</p> 


                        <p>What sets us apart:</p>
                        <ul>
                            <li>AI trend tracker to analyze trends, predict market demand, and zero down on potential innovations </li>
                            <li>Agile Ideation Framework for rapid iteration and adaptation according to evolving business ecosystem</li>
                            <li>Ideation frameworks and playbooks</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                           <p className="inline-list">AI and Machine Learning  |  IoT  |  Augmented reality  |  Virtual reality  |  Sustainable and ethical innovation</p>                  
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                <div className="text-side px-5 ms-5">
                        <h3>Product Engineering</h3>
                        <h4>Build groundbreaking products</h4>
                        
                        <p>We focus on creating innovative, scalable, and robust digital solutions that align with your business goals and meet market demands. From ideation and design to development, testing, and deployment, we take complete ownership of the entire product development lifecycle.</p>
                        <p>What sets us apart:</p>
                        
                        <ul>
                            <li>Generative product engineering & startup partners</li>
                            <li>Co-development and co-creation initiatives</li>
                            <li>Advanced technologies lab for rapid product development</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI and automation  |  Cloud computing  |  DevOps and Continuous Integration/Continuous Deployment (CI/CD)  |  IOT  |  Sustainable and green tech  |  Advanced data analytics</p>                  
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <div className="bg-2"></div>
                      {/* <img src={img2} alt="" /> */}
                </div>
            </Row>
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                      <div className="bg-3"></div>
                      {/* <img src={img3} alt="" /> */}
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5" >
                        <h3>AI Co-Pilot Enablement</h3>
                        <h4>Combine data, AI and human insight</h4>

                        <p>Our AI Co-Pilots provide businesses with intelligent, automated assistance designed to enhance decision-making, optimize operations, and elevate customer experiences.</p>
                        <p>What sets us apart:</p>
                        <ul>
                            <li>Industry-specific LLMs with contextual understanding and learning capabilities</li>
                            <li>Predictive analytics and decision enabled </li>
                            <li>Natural language processing enabled for human-like responses</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI and automation  |  Cloud computing  |  DevOps and Continuous Integration/Continuous Deployment (CI/CD)  |  IOT  |  Sustainable and green tech  |  Advanced data analytics</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                    <div className="text-side px-5 ms-5">
                        <h3>Innovation Labs & Venture Studios</h3>
                        <h4>Make synergy your competitive edge</h4>

                        <p>We equip businesses to fully leverage the power of open innovation through a unique combination of our venture fund-backed startup collaboration program, Quantum Leap and our rapid experimentation platform, SpeedX.</p>
                        <p>What sets us apart:</p>

                        <ul>
                           <li>Strategic Partnerships with cutting-edge startups</li>
                           <li>Co-Development and Co-Creation Initiatives</li>
                           <li>Investment and operation support for the startup ecosystem</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Cross-Industry Collaborations  |  Digital Platforms for Collaboration  |  Sustainability and Social Impact  |  Corporate Accelerators and Incubators</p>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                      <div className="bg-4"></div>
                      {/* <img src={img4} alt="" /> */}
                </div>
            </Row>
          
            <Row>
                <div className="col-md-12 text-center py-5">
                    <HashLink to="#contact" className="main-btn">Let's innovate</HashLink>
                </div>
            </Row>
        </div>


        <div className="container-fluid mobile tablet mt-5 px-0 mx-0">
                <div className="bg-img bg-1">
                    <div className="text-side">
                        <h3>Product Ideation</h3>
                        <h4>Transform ideas into breakthroughs</h4>                        
                        <p>We leverage AI and deep industry insights to streamline ideation, identify viable innovations, and transform abstract ideas into actionable project plans, setting the stage for successful development and launch.</p> 
                        <p>What sets us apart:</p>
                        <ul>
                            <li>AI trend tracker to analyze trends, predict market demand, and zero down on potential innovations </li>
                            <li>Agile Ideation Framework for rapid iteration and adaptation according to evolving business ecosystem</li>
                            <li>Ideation frameworks and playbooks</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                           <p className="inline-list">AI and Machine Learning  |  IoT  |  Augmented reality  |  Virtual reality  |  Sustainable and ethical innovation</p>                  
                    </div>
                </div>

            <div className="bg-img bg-2">
                <div className="text-side">
                        <h3>Product Engineering</h3>
                        <h4>Build groundbreaking products</h4>
                        
                        <p>We focus on creating innovative, scalable, and robust digital solutions that align with your business goals and meet market demands. From ideation and design to development, testing, and deployment, we take complete ownership of the entire product development lifecycle.</p>
                        <p>What sets us apart:</p>
                        
                        <ul>
                            <li>Generative product engineering & startup partners</li>
                            <li>Co-development and co-creation initiatives</li>
                            <li>Advanced technologies lab for rapid product development</li>
                            </ul> 
                            <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI and automation  |  Cloud computing  |  DevOps and Continuous Integration/Continuous Deployment (CI/CD)  |  IOT  |  Sustainable and green tech  |  Advanced data analytics</p>                  
                    </div>
                </div>

                <div className="bg-img bg-3">
                    <div className="text-side" >
                        <h3>AI Co-Pilot Enablement</h3>
                        <h4>Combine data, AI and human insight</h4>

                        <p>Our AI Co-Pilots provide businesses with intelligent, automated assistance designed to enhance decision-making, optimize operations, and elevate customer experiences.</p>
                        <p>What sets us apart:</p>
                        <ul>
                            <li>Industry-specific LLMs with contextual understanding and learning capabilities</li>
                            <li>Predictive analytics and decision enabled </li>
                            <li>Natural language processing enabled for human-like responses</li>
                        </ul> 
                        <h5>Disruptive industry trends:</h5>
                        <p className="inline-list">AI and automation  |  Cloud computing  |  DevOps and Continuous Integration/Continuous Deployment (CI/CD)  |  IOT  |  Sustainable and green tech  |  Advanced data analytics</p>
                    </div>
                </div>
       
                <div className="bg-img bg-4">
                 <div className="text-side">
                        <h3>Innovation Labs & Venture Studios</h3>
                        <h4>Make synergy your competitive edge</h4>

                        <p>We equip businesses to fully leverage the power of open innovation through a unique combination of our venture fund-backed startup collaboration program, Quantum Leap and our rapid experimentation platform, SpeedX.</p>
                        <p>What sets us apart:</p>

                        <ul>
                           <li>Strategic Partnerships with cutting-edge startups</li>
                           <li>Co-Development and Co-Creation Initiatives</li>
                           <li>Investment and operation support for the startup ecosystem</li>
                        </ul> 
                            <h5>Disruptive industry trends:</h5>
                            <p className="inline-list">Cross-Industry Collaborations  |  Digital Platforms for Collaboration  |  Sustainability and Social Impact  |  Corporate Accelerators and Incubators</p>
                    </div>
                </div>
            <Row>
                <div className="col-md-12 text-center py-0">
                    <HashLink to="#contact" className="main-btn">Let's innovate</HashLink>
                </div>
            </Row>
        </div>
    </section>
    </>
  )
}

export default TomorrowsChallenges
