import React from 'react'
// import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import './main-slider.css'
import { Row } from 'react-bootstrap';
// import slide5img from '../images/banner5-img.webp'

import slide1 from '../video/AI Copilots_HD.mp4';
import slide2 from '../video/Boost_Image.mp4';
import slide3 from '../video/Data oil_HD.mp4';
import slide4 from '../video/Visionary_HD.mp4';
import slide5 from '../video/Breaking barriers_HD.mp4';

import slide1mb from '../video/mobile/AI Copilots_HD_mb.mp4';
import slide2mb from '../video/mobile/Boost_Image_mb.mp4';
import slide3mb from '../video/mobile/Data oil_HD_mb.mp4';
import slide4mb from '../video/mobile/Visionary_HD_mb.mp4';
import slide5mb from '../video/mobile/Breaking barriers_HD_mb.mp4';

const MainSlider = () => {

  // const preventDefault = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation(); // Prevent event propagation
  //   // Do whatever you need here
  // };

  return (
    <section className='slider-section'>
         <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    centeredSlides={false}
                    loop={true}
                    autoplay={{
                      delay: 10000,
                      disableOnInteraction: false,
                    }}
                    // pagination={{
                    //   clickable: false,
                    // }}
                    pagination={{ clickable: true }}
                    navigation={false}
                    //modules={[ Pagination, Navigation]}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="home-slider"
                  >

                    <SwiperSlide className='slide1'>
                      <video className="background-video desktop" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide5} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>

                      <video className="background-video mobile tablet" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide5mb} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>


                        <div className="slide-box container">
                          <Row>
                              <div className='col-md-8'>
                              <div className="text-box">
                                {/* <div className="heading">Breaking Barriers.<br/> Crafting <span>Tomorrow's Solutions</span>  </div> */}
                                <div className="heading">
                                {/* <span>Automating Infrastructure Management</span>  to drive Cost & Operational Efficiency  */}
                                An <span>AI-First, Digital Engineering</span> Firm that Helps Accelerate Modernization and Innovation

                                </div>
                                <HashLink  className='slide-btn desktop-inline' to='/contact-us#contact' >Talk to us</HashLink>
                                <HashLink  className='slide-btn mobile-inline tablet-inline' to='/contact-us#contact' >Talk to us</HashLink>
                              </div>
                              </div>
                          </Row>
                        </div>
                      </SwiperSlide>

                      <SwiperSlide className='slide5'>
                      <video className="background-video desktop" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide4} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>


                      <video className="background-video mobile tablet" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide4mb} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>

                        <div className="slide-box container">
                        <Row>
                            <div className='col-md-8'>
                              <div className="text-box">
                                {/* <div className="heading desktop">
                                Unleashing Open Innovation:<br />
                                We Empower <span>Visionaries</span></div>
                                */}
                                <div className="heading desktop">
                                {/* Embracing <span>Future of <br />  Financial Services - <br />  Rapid Experimentation</span> <br /> with FinTech Propositions */}
                                <span>Venture Engineering</span> at <br /> our Core - Bringing Startup <br /> & Emerging Technologies <br /> to Enterprises
                                
                                </div>
                                <div className="heading mobile tablet">
                                {/* Embracing <span>Future of Financial Services - Rapid Experimentation</span> with FinTech Propositions */}
                                <span>Venture Engineering</span> at our Core - Bringing Startup & Emerging Technologies to Enterprises

                                </div>
                                <HashLink  className='slide-btn desktop-inline' to='/contact-us#contact' >Talk to us</HashLink>
                                <HashLink  className='slide-btn mobile-inline tablet-inline' to='/contact-us#contact' >Talk to us</HashLink>
                              </div>
                            </div>
                          </Row>

                          {/* <Row className='align-items-baseline'>
                              <div className='col-md-6'>
                              <div className="text-box">
                                <div className="heading">Where <span>best-of-breed</span> <br/> capabilities converge</div>
                                <NavLink className='slide-btn' to='#' >Elevate your future</NavLink>
                              </div>
                              </div>
                              <div className='col-md-6'>
                                <img src={slide5img} className='' alt='' />
                              </div>

                          </Row> */}
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className='slide2 justify-content-end'>
                      <video className="background-video desktop" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide2} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>

                      <video className="background-video mobile tablet" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide2mb} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>

                      <div className="slide-box container pb-5">
                          <Row>
                              <div className='col-md-12'>
                              <div className="text-box text-center pb-0 pb-md-5">
                                {/* <div className="heading">Boost Your Automation ROI<br/>With <span>AI-led</span> Hyper Automation</div> */}
                              
                                <div className="heading">
                                  {/* Boost your <span>Automation ROI</span> with <span>AI led</span> Intelligent Business Automation  */}
                                  <span>Build</span> Platforms <span>Rapidly</span> and Experiment in our Digital Studios
                                </div>
                                
                                <HashLink  className='slide-btn desktop-inline' to='/contact-us#contact' >Talk to us</HashLink>
                                <HashLink  className='slide-btn mobile-inline tablet-inline' to='/contact-us#contact' >Talk to us</HashLink>
                              </div>
                              </div>
                          </Row>
                        </div>
                      </SwiperSlide>


                      <SwiperSlide className='slide4 justify-content-end'>
                      <div className="slide-box container pb-5">
                      <video className="background-video desktop" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide1} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>

                      <video className="background-video mobile tablet" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide1mb} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>


                          <Row>
                              <div className='col-md-12'>
                              <div className="text-box text-center pb-0 pb-md-5">
                                {/* <div className="heading">
                                Your Success Story: <br/>Co-Written With <span>AI Co-Pilots</span></div> */}
                                <div className="heading">
                                {/* <span>Gen AI</span> is a growth lever - Do you have the right Risk & Regulatory guardrails in place? */}
                                Introducing <span>AI Infused Software Engineering</span> - Experience up to 60% Faster Release Velocity with Enhanced Quality
                                </div>
                                {/* <NavLink className='slide-btn' onClick={preventDefault} to='#' >Elevate your future</NavLink> */}
                                <HashLink  className='slide-btn desktop-inline' to='/contact-us#contact' >Talk to us</HashLink>
                                <HashLink  className='slide-btn mobile-inline tablet-inline' to='/contact-us#contact' >Talk to us</HashLink>
                              </div>
                              </div>
                          </Row>
                        </div>
                      </SwiperSlide>



                      <SwiperSlide className='slide3'>
                      <video className="background-video desktop" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide3} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>

                      <video className="background-video mobile tablet" playsInline  loop={true} muted={true} autoPlay={true} >
                          <source src={slide3mb} type="video/mp4" />
                          {/* <source src={bgvideo} type="video/ogg" /> */}
                          Your browser does not support the video tag.
                      </video>

                      <div className="slide-box container">
                          <Row>
                              <div className='col-md-8'>
                              <div className="text-box">
                                {/* <div className="heading">Data Is Not The New Oil.<br/><span>Insights Are</span></div> */}
                                <div className="heading">
                                  {/* Turbocharge your SDLC Efficiency - Embrace AI led <span>Quality Engineering</span>  */}
                                  Building <span>Secure AI Models</span> and Governance Frameworks to Drive Business Value  
                                </div>
                                <HashLink  className='slide-btn desktop-inline' to='/contact-us#contact' >Talk to us</HashLink>
                                <HashLink  className='slide-btn mobile-inline tablet-inline' to='/contact-us#contact' >Talk to us</HashLink>
                              </div>
                              </div>
                          </Row>
                        </div>
                      </SwiperSlide>


                      
           
         </Swiper>

    </section>
  )
}

export default MainSlider
