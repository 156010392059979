import React from 'react'

const MemberBio = (props) => {

  return (
    <div className='bio'>
        <div className='d-flex justify-content-end'>
            <span className='close'>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#292D32"/>
                <path d="M9.16937 15.5804C8.97937 15.5804 8.78938 15.5104 8.63938 15.3604C8.34938 15.0704 8.34938 14.5904 8.63938 14.3004L14.2994 8.64035C14.5894 8.35035 15.0694 8.35035 15.3594 8.64035C15.6494 8.93035 15.6494 9.41035 15.3594 9.70035L9.69937 15.3604C9.55937 15.5104 9.35937 15.5804 9.16937 15.5804Z" fill="#292D32"/>
                <path d="M14.8294 15.5804C14.6394 15.5804 14.4494 15.5104 14.2994 15.3604L8.63938 9.70035C8.34938 9.41035 8.34938 8.93035 8.63938 8.64035C8.92937 8.35035 9.40937 8.35035 9.69937 8.64035L15.3594 14.3004C15.6494 14.5904 15.6494 15.0704 15.3594 15.3604C15.2094 15.5104 15.0194 15.5804 14.8294 15.5804Z" fill="#292D32"/>
            </svg>
            </span>
        </div>
        <div className='row'>
            <div className='col-md-1'>
                <img className='desktop' src={props.img} alt=''/>
                <div className='mobile'>
                    <div className='bio-header-mb'>
                        <img src={props.img} alt=''/>
                        <div>
                            <h3>{props.name}</h3>
                            <p>{props.post}</p>
                        </div>

                    </div>
                
                </div>
            </div>
                <div className='col-md-11'>
                    <h3 className='desktop'>{props.name}</h3>
                    <p className='desktop'>{props.post}</p>
                <div className='des' dangerouslySetInnerHTML={{ __html: props.bio }}>
                    {/* <p>Jim Francis is the CEO and Co-Founder of ConceptVines Ventures (CVV), where he mentors founders, invests in startups, and provides board advisory services. He has an extensive background in technology consulting and engineering, having helped two organizations cross the $1 billion revenue mark and achieve successful exits. Jim has worked across all industries, including Banking & Financial Services, Insurance, Healthcare, and Life Sciences. He is a technologist with experience in various advanced technology areas such as AI/ML, blockchain, IIoT, and data analytics. Jim is a proven business strategist with experience running global business units, sales and marketing teams, alliance/channel partner relationships, and large deal teams. He has a global network of relationships at Fortune 500 accounts.</p>
                    <p>ConceptVines Ventures aims to improve startups' success rates by adding a higher level of active engagement with portfolio companies, providing capital investment, advisory services, and value-added services in product strategy, design, and engineering. Prior to starting ConceptVines, Jim held various leadership roles at Virtusa, a billion-dollar global technology firm with over 30,000 consultants and engineers, where he ran various business units, sales and marketing organizations, alliances, and formed the company's strategic deals group. He was also an executive at American Management Systems (AMS) in the Corporate Banking industry group and gained international experience and relationships as an expat living in London, Frankfurt, and Australia. Jim has an MBA from Binghamton University and a BS in Business Economics from Oneonta College.</p> */}
               </div>    
            </div>
       </div>
    </div>
  )
}

export default MemberBio
