import React from 'react'
import { Helmet } from 'react-helmet'
import Menu from '../components/Menu'
import Header2 from '../components/Header2'
import Footer from '../components/Footer'
import PrivacyBody from '../components/PrivacyBody'



const PrivacyPolicy = () => {
  return (
    <>
    <Helmet>
      <title>Privacy Policy</title>
      <meta property="og:title" content="Privacy Policy" />
      <meta name='og:description' content='Privacy Policy'/>
      <meta name='keywords' content=''/>
    </Helmet>
   
    <div>
        <Menu />
        <Header2 class="terms"/>
        <PrivacyBody />
        <Footer />      
    </div>

    </>
    )
}

export default PrivacyPolicy
