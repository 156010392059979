import React, {useEffect} from 'react'

import capabilities from "../images/Capabilities.webp"
import processes from "../images/Processes.webp"
import people from "../images/People.webp"
import { gsap } from "gsap";
import img  from "../images/new-chapter-mb.webp"

import './animation.css'

import { ScrollTrigger} from "gsap/ScrollTrigger";
// import LocomotiveScroll from "locomotive-scroll";

gsap.registerPlugin(ScrollTrigger);

const Animation = () => {

  useEffect(() => {

    ScrollTrigger.refresh();

    // // Using Locomotive Scroll
    
    // const locoScroll = new LocomotiveScroll({
    //   el: document.querySelector(".wwerapproach-section"),
    //   smooth: true
    // });
    // // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
    // locoScroll.on("scroll", ScrollTrigger.update);
    
    // // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
    // ScrollTrigger.scrollerProxy(".wwerapproach-section", {
    //   scrollTop(value) {
    //     return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
    //   }, // we don't have to define a scrollLeft because we're only scrolling vertically.
    //   getBoundingClientRect() {
    //     return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
    //   }
    // });
    
    


    let tl = gsap.timeline(
      {scrollTrigger: {
          trigger: ".wwerapproach-section",
          // start: "top bottom",
          // end: "top 130vh ",
          duration:7,
          start: "top top",
          end: "+1000px",
          scrub: 0.1,
          // scrub: "ture",
          // markers:true
        }}
    ); //create the timeline

    tl.to(".wwerapproach-section .first-panel", {
      position:"fixed",
      top:0,
      duration:0.5,
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      // start: "top 0px",
      // end: "+0px",
      // scrub: 0.5,
      // // scrub: "ture",      
      // // markers:true
      // }
    });



    tl.to(".wwerapproach-section .first-panel h2", {
      opacity:1,
      duration:0.5,
      
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      // // start: "top bottom",
      // // end: "top 130vh ",
      // start: "top -50px",
      // end: "+0px",
      // // scrub: "ture",
      // scrub: 0.5,
      // // markers:true
      // }
    });

    tl.to(".wwerapproach-section .first-panel .panel", {
      width: '70%',
      height: '85%',
      duration:1,
      // scrollTrigger: {
    //     trigger: ".wwerapproach-section",
    //     start: "top -70px",
    //     end: "+69px",
    //     scrub: 0.5,
    //  // markers:true
    // }
    });

    tl.to(".wwerapproach-section .first-panel .panel", {
      width: '80%',
      height: '90%',
      duration:1,
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      //   start: "top -140px",
      //   end: "+69px",
      //   scrub: 0.5,
      //   // markers:true
      // }
    });

    tl.to(".wwerapproach-section .first-panel .panel", {
      width: '90%',
      height: '95%',
      duration:1,
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      //   start: "top -210px",
      //   end: "+69px",
      //   scrub: 0.5,
      //   // markers:true
      // }
    });

    
    tl.to(".wwerapproach-section .first-panel .panel", {
      width: '100%',
      height: '100%',
      duration:1,
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      //   start: "top -280px",
      //   end: "+0px",
      //   scrub: 0.5,
      //   // markers:true
      // }
    });

    // tl.to(".first-panel .panel", {
    //   width: '100%',
    //   height: '100%',
    //   duration:5,
    //   scrub: 0.5,
    //   // scrollTrigger: {
    //   //   trigger: ".wwerapproach-section",
    //   //   start: "top -280px",
    //   //   end: "+0px",
    //   //   scrub: 0.5,
    //   //   // markers:true
    //   // }
    // });

    tl.to(".wwerapproach-section .first-panel", {
      display:"none",
      top:"unset",
      duration:0.2,
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      // start: "top -320px",
      // end: "0px",
      // scrub: 0.5,
      // // markers:true
      // }
    });


    tl.to(".wwerapproach-section .secand-panel", {
      zIndex:3,
      opacity:1,
      duration:0.2,
    //   scrollTrigger: {
    //     trigger: ".wwerapproach-section",
    //   // start: "top bottom",
    //   // end: "top 130vh ",
    //   start: "top -320px",
    //   end: "+10px",
    //   scrub: 0.5,
    //   // pin:true,    
    // //   markers:true
    //   }
    });

 
    tl.fromTo(".wwerapproach-section .approach .imgb", {yPercent:-100,}, {delay:0.1, duration: 0.125, yPercent:0,height:"auto",
    scrollTrigger:false,
    //      scrollTrigger: {
    //   trigger: ".wwerapproach-section",
    //   start: "top -320px",
    //   end: "0px",
    //   scrub: 0.9,
    //   stagger: 0.5,
    //   ease: "steps(12)",
    //   // pin:true,    
    // //   markers:true
    //   }
 })

 //     gsap.fromTo(".approach .imgb img", {yPercent:100}, {duration: 0.5, yPercent: 0,
//          scrollTrigger: {
//       trigger: ".wwerapproach-section",
//       start: "top -450px",
//       end: "450px",
//       scrub: 0.5,
//       stagger: 0.5,
//       markers:true
//       }
//  }, "<")

    // tl.to(".approach .imgb", {
    //   // delay:4,
    //   // opacity:1,
    //   duration:1,
    //   height:"auto",
    //   // ease: "power5.ease",      
    //   scrollTrigger: {
    //   trigger: ".wwerapproach-section",
    //   start: "top -325px",
    //   end: "+0px",
    //   scrub: 0.5,
    //   delay: 1, 
    //   stagger: 1.5,
    //   // markers:true
    //   }
    // });


    // tl.to(".approach .imgb img", {
    //   // delay:4,
    //   // display:"block",
      
    //   duration:1,
    //   opacity:1,
    //   height:"auto",
    //   // ease: "power5.ease",      
    //   scrollTrigger: {
    //   trigger: ".wwerapproach-section",
    //   start: "top -325px",
    //   end: "+0px",
    //   scrub: 0.5,
    //   delay: 1, 
    //   stagger: 1.5,
    //   markers:true
    //   }
    // });

    
    tl.to(".wwerapproach-section .approach .textbox", {
      opacity:1,
      translateY:0,
      // delay:0.1,
      duration:0.125,
      // scrub:0.1
      // ease: "power5.ease",      
    //   scrollTrigger: {
    //   trigger: ".wwerapproach-section",
    //   start: "top -335px",
    //   end: "+0px",
    //   scrub: 0.5,
    //   delay: 1, 
    //   stagger: 1.5,
    // //   markers:true
    //   }
    });
    


    /*
    let tl = gsap.timeline(
      {scrollTrigger: {
          trigger: ".wwerapproach-section",
          // start: "top bottom",
          // end: "top 130vh ",
          start: "top top",
          end: "+380px",
          scrub: 0.5,
          ease: "power5.inOut",
          // scrub: "ture",
          // markers:true
        }}
    ); //create the timeline

    tl.to(".first-panel", {
      position:"fixed",
      top:0,
      scrollTrigger: {
        trigger: ".wwerapproach-section",
      // start: "top bottom",
      // end: "top 130vh ",
      start: "top 0px",
      end: "+300px",
      scrub: 0.5,
      // scrub: "ture",
      
      // markers:true
      }
    });



    tl.to(".first-panel h2", {
      display:"block",
      
      scrollTrigger: {
        trigger: ".wwerapproach-section",
      // start: "top bottom",
      // end: "top 130vh ",
      start: "top -50px",
      end: "+10px",
      // scrub: "ture",
      scrub: 0.5,
      // markers:true
      }
    });

    tl.to(".first-panel .panel", {
      width: '70%',
      height: '85%',
      scrollTrigger: {
        trigger: ".wwerapproach-section",
        start: "top -70px",
        end: "+69px",
        scrub: 0.5,
      // markers:true
    }
    });

    tl.to(".first-panel .panel", {
      width: '80%',
      height: '90%',
      scrollTrigger: {
        trigger: ".wwerapproach-section",
        start: "top -140px",
        end: "+69px",
        scrub: 0.5,
        // markers:true
      }
    });

    tl.to(".first-panel .panel", {
      width: '90%',
      height: '95%',
      scrollTrigger: {
        trigger: ".wwerapproach-section",
        start: "top -210px",
        end: "+69px",
        scrub: 0.5,
        // markers:true
      }
    });

    
    tl.to(".first-panel .panel", {
      width: '100%',
      height: '100%',
      scrollTrigger: {
        trigger: ".wwerapproach-section",
        start: "top -280px",
        end: "+69px",
        scrub: 0.5,
        // markers:true
      }
    });

    tl.to(".first-panel", {
      display:"none",
      top:"unset",
      scrollTrigger: {
        trigger: ".wwerapproach-section",
      start: "top -300px",
      end: "0px",
      scrub: 0.5,
      // markers:true
      }
    });


    tl.to(".secand-panel", {
      zIndex:3,
      scrollTrigger: {
        trigger: ".wwerapproach-section",
      // start: "top bottom",
      // end: "top 130vh ",
      start: "top -355px",
      end: "+0px",
      scrub: 0.5,
      // markers:true
      }
    });

   tl.to(".approach .imgb", {
      // delay:1,
      opacity:1,
      height:"auto",
      // ease: "power5.inOut",      
      scrollTrigger: {
      trigger: ".wwerapproach-section",
      start: "top -355px",
      end: "+30px",
      scrub: 0.5,
      markers:true
      }
    });

    tl.to(".approach .imgb img", {
      // delay:1,
      opacity:1,
      height:"auto",
      // ease: "power5.inOut",      
      scrollTrigger: {
      trigger: ".wwerapproach-section",
      start: "top -355px",
      end: "+30px",
      scrub: 0.5,
      markers:true
      }
    });



    // // tl.to(".approach ", {
    // //   // delay:4,
    // //   // display:"block",
    // //   opacity:1,
    // //   height:"100%",
    // //   ease: "power5.inOut",
      
    // //   scrollTrigger: {
    // //   trigger: ".wwerapproach-section",
    // //   start: "top -350px",
    // //   end: "+30px",
    // //   scrub: 0.5,
    // //   // markers:true
    // //   }
    // // });

    // tl.to(".approach .imgb", {
    //   // delay:1,
    //   opacity:1,
    //   height:"auto",
    //   ease: "power5.inOut",      
    //   scrollTrigger: {
    //   trigger: ".wwerapproach-section",
    //   start: "top -350px",
    //   end: "+30px",
    //   scrub: 0.5,
    //   markers:true
    //   }
    // });

    // tl.to(".approach .imgb img", {
    //   // delay:1,
    //   opacity:1,
    //   height:"auto",
    //   ease: "power5.inOut",      
    //   scrollTrigger: {
    //   trigger: ".wwerapproach-section",
    //   start: "top -350px",
    //   end: "+30px",
    //   scrub: 0.5,
    //   markers:true
    //   }
    // });

    
    // // tl.to(".approach", {
    // //   // delay:4,
    // //   opacity:1,
    // //   height:"unset",
    // //   ease: "power5.inOut",
      
    // //   scrollTrigger: {
    // //   trigger: ".wwerapproach-section",
    // //   // start: "top bottom",
    // //   // end: "top 130vh ",
    // //   start: "top -350px",
    // //   end: "+30px",
    // //   scrub: 0.5,
    // //   // markers:true
    // //   } 
    // // });

  */
    


// 
    
    // tl.to(".first-panel", {
    //   position:"absolute",
    //   top:0,
    //   scrollTrigger: {
    //     trigger: ".wwerapproach-section",
    //   // start: "top bottom",
    //   // end: "top 130vh ",
    //   start: "top -300px",
    //   end: "top +10px",
    //   scrub: 0.5,
    //   // markers:true
    //   }
    // });

    /*
    let tl = gsap.timeline(
      {scrollTrigger: {
          trigger: ".wwerapproach-section",
          // start: "top bottom",
          // end: "top 130vh ",
          start: "top 300px",
          end: "+280px",
          scrub: 0.5,
          // // markers:true
        }}
    ); //create the timeline

    tl.to(".first-panel h2", {
      display:"block",
      
      scrollTrigger: {
        trigger: ".wwerapproach-section",
      // start: "top bottom",
      // end: "top 130vh ",
      start: "top 300px",
      end: "+50px",
      scrub: 0.5,
      // markers:true
      }
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      //   // start: "top bottom",
      //   // end: "top 130vh ",
      //   start: "top 30%",
      //   end: "0",
      //   scrub: 1,
      // }
    });

    tl.to(".first-panel .panel", {
      width: '100%',
      height: '100%',
      // // left:"50%",
      // // top:"0px",
      // // transform:'translate(-50%)',
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      //   // start: "top bottom",
      //   // end: "top 130vh ",
      //   start: "top 200px",
      //   end: "top 0",
      //   scrub: 0.5,
      // }
      
      scrollTrigger: {
        trigger: ".wwerapproach-section",
      // start: "top bottom",
      // end: "top 130vh ",
      start: "top 200px",
      end: "top top",
      scrub: 0.5,
      // markers:true
      }
    });

    tl.to(".secand-panel", {
      zIndex:3,
      // // left:"50%",
      // // top:"0px",
      // // transform:'translate(-50%)',
      // scrollTrigger: {
      //   trigger: ".wwerapproach-section",
      //   // start: "top bottom",
      //   // end: "top 130vh ",
      //   start: "top 0px",
      //   end: "top 0px",
      //   scrub: true,
      // }
     
      scrollTrigger: {
        trigger: ".wwerapproach-section",
      // start: "top bottom",
      // end: "top 130vh ",
      start: "top -10px",
      end: "0px",
      scrub: 0.5,
      // markers:true
      }

    });


    tl.to(".approach .imgb, .approach .imgb img", {
      // delay:4,
      display:"block",
      opacity:1,
      maxHeight:"unset",
      // left:"50%",
      // top:"0px",
      // transform:'translate(-50%)',
      ease: "power5.inOut",
      // scrollTrigger: {
      // trigger: ".wwerapproach-section",
      //  // start: "top bottom",
      //  // end: "top 130vh ",
      //   start: "top 0px",
      //   end: "+200px",
      //   scrub: true,
      //   // markers:true
      // }
      
      scrollTrigger: {
      trigger: ".wwerapproach-section",
      // start: "top bottom",
      // end: "top 130vh ",
      start: "top -10px",
      end: "+20px",
      scrub: 0.5,
      // markers:true
      }
    });

    
    tl.to(".approach", {
      // delay:4,
      opacity:1,
      maxHeight:"unset",
      ease: "power5.inOut",
      
      scrollTrigger: {
      trigger: ".wwerapproach-section",
      // start: "top bottom",
      // end: "top 130vh ",
      start: "top -50px",
      end: "+20px",
      scrub: 0.5,
      // markers:true
      } 
    });

  */  

    // gsap.to(".first-panel h2", {
    //     display:"block",
    //     scrollTrigger: {
    //       trigger: ".wwerapproach-section",
    //       // start: "top bottom",
    //       // end: "top 130vh ",
    //       start: "top 30%",
    //       end: "0",
    //       scrub: 1,
    //     }
    //   });

    // gsap.to(".first-panel .panel", {
    //     width: '100%',
    //     height: '100%',
    //     // left:"50%",
    //     // top:"0px",
    //     // transform:'translate(-50%)',
    //     scrollTrigger: {
    //       trigger: ".wwerapproach-section",
    //       // start: "top bottom",
    //       // end: "top 130vh ",
    //       start: "top 200px",
    //       end: "top 0",
    //       scrub: 0.5,
          
    //     }
    //   });

    //   gsap.to(".secand-panel", {
    //     zIndex:3,
    //     // left:"50%",
    //     // top:"0px",
    //     // transform:'translate(-50%)',
    //     scrollTrigger: {
    //       trigger: ".wwerapproach-section",
    //       // start: "top bottom",
    //       // end: "top 130vh ",
    //       start: "top 0px",
    //       end: "top 0px",
    //       scrub: true,
    //     }
    //   });

        
    // let tl = gsap.timeline(
    //   {scrollTrigger: {
    //       trigger: ".wwerapproach-section",
    //       // start: "top bottom",
    //       // end: "top 130vh ",
    //       start: "top top",
    //       end: "+200px",
    //       scrub: true,
    //       // markers:true
    //     }}
    // ); //create the timeline



    // // tl.fromTo(".approach .imgb",
    // // // {scrollTrigger: {
    // // //   trigger: ".wwerapproach-section",
    // // //   // start: "top bottom",
    // // //   // end: "top 130vh ",
    // // //   start: "top top",
    // // //   // end: "top +20px",
    // // //   // scrub: true,
    // // //   // markers:true
    // // // }},
    // // { height:0 }, { height:"100%"}) //start sequencing
    // //   // .to(".class2", { y: 100, ease: "elastic" })
    // //   // .to(".class3", { rotation: 180 });
    
    // //   // gsap.fromTo(".approach",  {scrollTrigger: {
    // //   //   trigger: ".wwerapproach-section",
    // //   //   // start: "top bottom",
    // //   //   // end: "top 130vh ",
    // //   //   start: "top 100px",
    // //   //   end: "+50px",
    // //   //   scrub: true,
    // //   //   // markers:true,
    // //   // }},{ 
    // //   //   height: 0 
    // //   // }, { 
    // //   //   height: "auto",
    // //   //   // duration: 1 ,
    // //   // },
    // //   // );

      
      
    //   gsap.to(".approach .imgb, .approach .imgb img", {
    //     maxHeight:"unset",
    //     duration:3,
    //     // opacity:1,
    //     // left:"50%",
    //     // top:"0px",
    //     // transform:'translate(-50%)',
    //     ease: "power3.inOut",
    //     scrollTrigger: {
    //       trigger: ".wwerapproach-section",
    //       // start: "top bottom",
    //       // end: "top 130vh ",
    //       start: "top 0px",
    //       end: "+200px",
    //       scrub: true,
    //       // markers:true
    //     }
    //   });

    // //   gsap.to(".secand-panel .approach .imgb img", {
    // //      height:"100%",
    // //      scrollTrigger: {
    // //       trigger: ".approach",
    // //       start: "top 100px",
    // //       end:"+200px",
    // //       scrub: 1,
    // //       // markers:true
    // //     }
    // //   });

  });


  return (
    <>
      <section className='wwerapproach-section desktop'>
          <div className='container-fluid first-panel'>
              <div className='panel' >
                  <h2>Scripting a new chapter with <br />AI-First approach</h2>
              </div>
          </div>
          <div className='secand-panel'>
              <div className='container'>
              <div className='approach-row'>
                      <div className='approach-col'>
                          <div className='approach'>
                              <div className='imgb'><img src={capabilities} alt=''/></div>
                              <div className='textbox'>
                                  <h3>Capabilities</h3>
                                  <p>We leverage our proprietary AI-trend tracker to proactively identify untapped opportunities and strategically invest in startups to address customer challenges.</p>
                              </div>
                          </div>
                      </div>
                      <div className='approach-col'>
                          <div className='approach'>
                          <div className='imgb'><img src={processes} alt=''/></div>
                              <div className='textbox'>
                                  <h3>Processes</h3>
                                  <p>We turbocharge our delivery process with cutting-edge generative AI-based commercial tools and SaaS platforms to strategically reduce costs and continually invest in research and development.</p>   
                              </div>
                          </div>
                      </div>
                      <div className='approach-col'>
                          <div className='approach'>
                          <div className='imgb'><img src={people} alt=''/></div>
                              <div className='textbox'>
                                  <h3>People</h3>
                                  <p>We deploy an AI-first approach to effortlessly source and train top talent in advanced technologies, bringing in unparalleled efficiency in workforce development.</p>
                              </div>        
                          </div>
                      </div>
                      
                  </div>

                  {/* <Row className='g-0'>
                      <div className='col-md-4'>
                          <div className='approach'>
                              <div className='imgb'><img src={capabilities} alt=''/></div>
                              <div className='textbox'>
                                  <h3>Capabilities</h3>
                                  <p>We leverage our proprietary AI-trend tracker to proactively identify untapped opportunities and strategically invest in startups to address customer challenges.</p>
                              </div>
                          </div>
                      </div>
                      <div className='col-md-4'>
                          <div className='approach'>
                          <div className='imgb'><img src={processes} alt=''/></div>
                              <div className='textbox'>
                                  <h3>Processes</h3>
                                  <p>We turbocharge our delivery process with cutting-edge generative AI-based commercial tools and SaaS platforms to strategically reduce costs and continually invest in research and development.</p>   
                              </div>
                          </div>
                      </div>
                      <div className='col-md-4'>
                          <div className='approach'>
                          <div className='imgb'><img src={people} alt=''/></div>
                              <div className='textbox'>
                                  <h3>People</h3>
                                  <p>We deploy an AI-first approach to effortlessly source and train top talent in advanced technologies, bringing in unparalleled efficiency in workforce development.</p>
                              </div>        
                          </div>
                      </div>
                      
                  </Row> */}
              </div>
          </div>
      </section>

      <section className='wwerapproach-mobile-section mobile'>
        <div className='container-fluid first-panel'>
            <div className='panel' >
                <img src={img} alt='' />
                <h2>Scripting a new chapter with AI-First approach</h2>
            </div>
        </div>
        <div className='secand-panel'>
            <div className='container'>
            <div className='approach-row'>
                    <div className='approach-col'>
                        <div className='approach'>
                            <div className='imgb'><img src={capabilities} alt=''/></div>
                            <div className='textbox'>
                                <h3>Capabilities</h3>
                                <p>We leverage our proprietary AI-trend tracker to proactively identify untapped opportunities and strategically invest in startups to address customer challenges.</p>
                            </div>
                        </div>
                    </div>
                    <div className='approach-col'>
                        <div className='approach'>
                        <div className='imgb'><img src={processes} alt=''/></div>
                            <div className='textbox'>
                                <h3>Processes</h3>
                                <p>We turbocharge our delivery process with cutting-edge generative AI-based commercial tools and SaaS platforms to strategically reduce costs and continually invest in research and development.</p>   
                            </div>
                        </div>
                    </div>
                    <div className='approach-col'>
                        <div className='approach'>
                        <div className='imgb'><img src={people} alt=''/></div>
                            <div className='textbox'>
                                <h3>People</h3>
                                <p>We deploy an AI-first approach to effortlessly source and train top talent in advanced technologies, bringing in unparalleled efficiency in workforce development.</p>
                            </div>        
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
    </section>

    </>
  )
}

export default Animation
