
// import { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';

// function ScrollToTop({ children }) {
//   const location = useLocation();
//   const [isLoaded, setIsLoaded] = useState(false);

//   useEffect(() => {
//     if (!isLoaded) {
//       window.scrollTo(0, 0);
//       setIsLoaded(true);
//     }
//   }, [location.pathname, isLoaded]); 

//   return children;
// }

// export default ScrollToTop;


import { useEffect,useState } from 'react';
import { useLocation } from 'react-router-dom';


const ScrollToTop = () => {
 const location = useLocation();
 const [blank, setBlank] = useState(false);

  useEffect(() => {
    // console.log(location.pathname);

    if(location.pathname !== "/") {
        setBlank(true);
        // setTimeout(() => {
            window.scrollTo(0 , 0);
        // }, 100);
        setTimeout(() => {
        setBlank(false);            
        }, 1000);          
    }    

}, [location.pathname]);
  
    return (
        <>
            {(blank) ? <div className='blank'> </div> : ""}
        </>
    )
}

export default ScrollToTop

  