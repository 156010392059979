import React from 'react'
import { NavLink } from 'react-router-dom';
import './homecatapulating.css';
import { Row } from 'react-bootstrap';

import himg1 from '../images/quantumleap.webp';
import himg2 from '../images/speedx.webp';
import himg3 from '../images/elevate.webp';

import pimg1 from '../images/AWS-Logo-w.webp';
import pimg2 from '../images/GCloud-Logo-w.webp';
import pimg3 from '../images/Snowflake_Logo-w.webp';
import pimg4 from '../images/databricks_logo-w.webp';
import pimg5 from '../images/Pega-Logo-w.webp';
// import pimg6 from '../images/Appian-w.webp';
import pimg6 from '../images/Meta-logo.webp';

import pimg7 from '../images/Azure-Logo-w.webp';
import pimg8 from '../images/Terraform_Logo-w.webp';
import pimg9 from '../images/OpenAI-Logo-w.webp';
import pimg10 from '../images/Anthropic_logo-w.webp';
import pimg11 from '../images/UiPath-w.webp';
import pimg12 from '../images/hf-logo.webp';


import pimgmb1 from '../images/AWS-Logo-w-mb.webp';
import pimgmb2 from '../images/GCloud-Logo-w-mb.webp';
import pimgmb3 from '../images/Snowflake_Logo-w-mb.webp';
import pimgmb4 from '../images/databricks_logo-w-mb.webp';
import pimgmb5 from '../images/Pega-Logo-w-mb.webp';
import pimgmb6 from '../images/Meta-logo.webp';

import pimgmb7 from '../images/Azure-Logo-w-mb.webp';
import pimgmb8 from '../images/Terraform_Logo-w-mb.webp';
import pimgmb9 from '../images/OpenAI-Logo-w-mb.webp';
import pimgmb10 from '../images/Anthropic_logo-w-mb.webp';
import pimgmb11 from '../images/UiPath-w-mb.webp';
import pimgmb12 from '../images/hf-logo.webp';


const HomeCatapulating = () => {
  return (
    <section className='catapulating-section'>
        <div className="container py-5">
            <div className="bl-green pt-0 pb-3">
                <h2 className='desktop tablet'>Catapulting Businesses to <span>Success</span></h2>
                <h2 className='mobile '>Catapulting <br /> Businesses to <span>Success</span></h2>
                <div className="text-box">
                    <p className='desktop'>Our flagship programs are designed to harness and deliver cutting-edge capabilities to <br /> equip businesses to maximize revenue, minimize cost and enhance productivity.</p>
                    <p className='mobile tablet'>Our flagship programs are designed to harness and deliver cutting-edge capabilities to equip businesses to maximize revenue, minimize cost and enhance productivity.</p>
                </div>
            </div>


            <Row className="anima-boxes g-5">
                <div className="col-md-4">
                    <div className='anima-box'>
                        <div className='img-box'>
                            <img src={himg1} alt=''/>
                        </div>
                        <div className="text-box blue">
                            <h3>Quantum Leap</h3>
                            <h4>Redefining possibilities with cutting-edge solutions</h4>
                            <p>We spot disruptors, invest boldly, and unravel solutions that uniquely solve our clients' toughest challenges. </p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                <div className='anima-box'>
                        <div className='img-box'>
                            <img src={himg2} alt=''/>
                        </div>
                        <div className="text-box green">
                            <h3>SpeedX</h3>
                            <h4>Pushing the limits of advanced technology</h4>
                            <p>We turn insights from continuous experimentation into your competitive edge. Prototype, learn, and build, not in months, but in days, tapping into cutting-edge tech from disruptive startups.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                <div className='anima-box'>
                        <div className='img-box'>
                            <img src={himg3} alt=''/>
                        </div>
                        <div className="text-box yellow">
                            <h3>Elevate</h3>
                            <h4>Leveling up tech talent</h4>
                            <p>We nurture a continuous flow of talent through our Learning and Development initiative, crafted in collaboration with EdTech pioneers and renowned universities.</p>
                        </div>
                    </div>
                </div>

            </Row>
            <Row className='py-5 text-center'>
                <div className='col-md-12 btn-row'>
                  <NavLink className='main-btn' to='/contact-us#contact' > Talk to us</NavLink>
                </div>
                <div className='col-md-12 pt-5 pb-3'>
                    <h1>Advanced technology partners</h1>
                </div>

                <div className='py-5 partners-6 desktop'>
                    <img src={pimg1} alt='' />
                    <img src={pimg2} alt='' />
                    <img src={pimg3} alt='' />
                    <img src={pimg4} alt='' />
                    <img src={pimg5} alt='' />
                    <img src={pimg6} alt='' />
                </div>

                <div className='py-5 partners-5 desktop'>
                    <img src={pimg7} alt='' />
                    <img src={pimg8} alt='' />
                    <img src={pimg9} alt='' />
                    <img src={pimg10} alt='' />
                    <img src={pimg11} alt='' />
                    <img src={pimg12} alt='' />
                </div>


                <div className='partners-6 mobile'>
                    <img src={pimgmb1} alt='' />
                    <img src={pimgmb2} alt='' />
                    <img src={pimgmb3} alt='' />
                    <img src={pimgmb4} alt='' />
                    <img src={pimgmb5} alt='' />
                    <img src={pimgmb6} alt='' />
                    <img src={pimgmb7} alt='' />
                    <img src={pimgmb9} alt='' />
                    <img src={pimgmb11} alt='' />
                </div>

                <div className=' partners-5 mobile '>
                    <img src={pimgmb8} alt='' />
                    <img src={pimgmb10} alt='' />
                    <img src={pimgmb12} alt='' />
                </div>

                <div className='partners-6 tablet'>
                    <img src={pimg1} alt='' />
                    <img src={pimg2} alt='' />
                    <img src={pimg3} alt='' />
                    <img src={pimg4} alt='' />
                    <img src={pimg5} alt='' />
                    <img src={pimg6} alt='' />
                    <img src={pimg7} alt='' />
                    <img src={pimg9} alt='' />
                    <img src={pimg11} alt='' />
                    <img src={pimg8} alt='' />
                    <img src={pimg10} alt='' />
                    <img src={pimg12} alt='' />
                </div>

            </Row>

        </div>
        
    </section>
  )
}

export default HomeCatapulating
