import React from "react"
import "./poweredby.css"

import { Row } from "react-bootstrap"

const PoweredBy = () => {
  return (
    <>
    <section className="poweredby-section">
        {/* <div className="container">
            <div className="main-box">
                    <h1>
                    Unleashing, <span>cloud-driven</span> <br /> 
                    brilliance or excellence
                    </h1>
            </div>
        </div> */}

        <div className="container-fluid px-0 mx-0 desktop">
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                        <div className="bg-1"></div>
                </div>
                <div className="col-md-8 order-m-1">
                    <div className="text-side pe-5 ps--5">
                        <h3>Powered by Emerging Technology</h3>
                        <p>A core belief is that companies need to balance their existing investments in more mainstream technology platforms and service providers with continued access to emerging technology and new servicing models & providers.</p>
                        <p>Our company has two distinct, but tightly integrated purposes. First is our Venture business, ConceptVines Ventures, which is focused on investing in and partnering extensively with disruptive technology companies. Complementing our Ventures business is our Consulting & Technology business, which is specialized in software product engineering.</p>
                        <p>We build industrial strength software platforms for the high-tech startup and scale-up industry as well as for Enterprise clients. We pride ourselves as being Industry and Technology Specialists vs. Generalists.</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5">   
            <div className="col-md-8">
                <div className="text-side px-5 ms-5">
                        <h3>Our Name Explains our DNA & Purpose</h3>
                        <p>Breaking down the company name, “Concept” is the planting of a seed or new idea connected to the word “Vines” which is symbolic of rapid growth. This aligns directly to our thesis of investing in Seed-to-Growth startups and helping them drive market adoption and growth. This concept also aligns to helping our Enterprise clients on conceptualizing, building and deploying new high growth business models and next generation bespoke technology platforms.</p>

                        <p>As a small fun fact, inverting the company initials of CV (ConceptVines) turns into VC (Venture Capital) which subtly brings out the importance of leveraging portfolio companies and other technologies to being able to infuse innovation into our Enterprise clients. This is a core differentiating part of our business model.</p>

                        <p>We believe that true innovation is often best driven by entrepreneurs and startups. We help bring these people and companies into Enterprises while also helping Enterprises work to 'think and do' more like creators and founders.</p>
                                          
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <div className="bg-2"></div>
                </div>
            </Row>
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                      <div className="bg-3"></div>
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5 ps--5">
                        <h3>Venture Engineering</h3>

                        <p>To sum up our company's purpose and identity into a few select words, it would be VENTURE ENGINEERING. We leverage private capital to invest in innovative startups and make them a part of our solution base to help drive more innovation to the market.</p>

                        <p><b>For our Portfolio companies,</b> we strive to be a full-service partner in supporting their business needs across Capital Raises, Product Strategy, Design & Engineering, and also a strategic Sales and Marketing partner for growing their business.</p>

                        <p><b>For our Enterprise clients,</b> our focus is to be seen as a strategic part of their Product Strategy, as well as be a part of their Design & Engineering Team.</p>

                        <p>Our clients have many partners ranging from Strategic Consulting to Outsourcing Services to System Integrators. ConceptVines's primary focus is to be an Innovation and Product Engineering Partner.</p>
                    </div>
                </div>
            </Row>

        </div>

        <div className="container-fluid mt-5 px-0 mx-0 mobile tablet">
           <div className="bg-img bg-1">
                    <div className="text-side ">
                    <h3>Powered by Emerging Technology</h3>
                        <p>A core belief is that companies need to balance their existing investments in more mainstream technology platforms and service providers with continued access to emerging technology and new servicing models & providers.</p>
                        <p>Our company has two distinct, but tightly integrated purposes. First is our Venture business, ConceptVines Ventures, which is focused on investing in and partnering extensively with disruptive technology companies. Complementing our Ventures business is our Consulting & Technology business, which is specialized in software product engineering.</p>
                        <p>We build industrial strength software platforms for the high-tech startup and scale-up industry as well as for Enterprise clients. We pride ourselves as being Industry and Technology Specialists vs. Generalists.</p>                  
                    </div>
            
           </div>

           <div className="bg-img bg-2">
                <div className="text-side">
                        <h3>Our Name Explains our DNA & Purpose</h3>
                        <p>Breaking down the company name, “Concept” is the planting of a seed or new idea connected to the word “Vines” which is symbolic of rapid growth. This aligns directly to our thesis of investing in Seed-to-Growth startups and helping them drive market adoption and growth. This concept also aligns to helping our Enterprise clients on conceptualizing, building and deploying new high growth business models and next generation bespoke technology platforms.</p>

                        <p>As a small fun fact, inverting the company initials of CV (ConceptVines) turns into VC (Venture Capital) which subtly brings out the importance of leveraging portfolio companies and other technologies to being able to infuse innovation into our Enterprise clients. This is a core differentiating part of our business model.</p>

                        <p>We believe that true innovation is often best driven by entrepreneurs and startups. We help bring these people and companies into Enterprises while also helping Enterprises work to 'think and do' more like creators and founders.</p>
                                                            
                    </div>
                </div>
            

                <div className="bg-img bg-3">
                    <div className="text-side">
                    <h3>Venture Engineering</h3>
                        <p>To sum up our company's purpose and identity into a few select words, it would be VENTURE ENGINEERING. We leverage private capital to invest in innovative startups and make them a part of our solution base to help drive more innovation to the market.</p>

                        <p><b>For our Portfolio companies,</b> we strive to be a full-service partner in supporting their business needs across Capital Raises, Product Strategy, Design & Engineering, and also a strategic Sales and Marketing partner for growing their business.</p>

                        <p><b>For our Enterprise clients,</b> our focus is to be seen as a strategic part of their Product Strategy, as well as be a part of their Design & Engineering Team.</p>

                        <p>Our clients have many partners ranging from Strategic Consulting to Outsourcing Services to System Integrators. ConceptVines's primary focus is to be an Innovation and Product Engineering Partner.</p>
                    </div>
                </div>
           
        </div>
    </section>
    </>
  )
}

export default PoweredBy
