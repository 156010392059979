import './App.css';
import { useEffect } from 'react';
// import LocomotiveScroll from "locomotive-scroll";

import 'bootstrap/dist/css/bootstrap.min.css';

import {Routes , Route } from "react-router-dom";
import ReactGA from 'react-ga4';


// import { gsap } from "gsap";
import Home from './pages/Home';
import WhoWrAre from './pages/WhoWrAre';
import CloudApplication from './pages/CloudApplication';
import DataAI from './pages/DataAI';
import DigitalInnovation from './pages/DigitalInnovation';
import IntelligentAutomation from './pages/IntelligentAutomation';
import ContactUs from './pages/ContactUs';
import ScrollToTop from './components/ScrollToTop';
import Ventures from './pages/Ventures';
import JoinUs from './pages/JoinUs';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import WhoWrAre2 from './pages/WhoWeAre2';
// import Page from './components/Page';

// import { useEffect } from 'react';
// import { ScrollTrigger} from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

ReactGA.initialize('G-FP0B0D67N4');


function App() {


  useEffect(() => {
    // ReactGA.pageview(window.location.pathname);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search , title: "Home" });
  }, []);

  // useEffect(()=>{
  //   // const scroll = new LocomotiveScroll({
  //   //   el: document.querySelector(".App"),
  //   //   smooth: true
  //   // });


  //   ScrollTrigger.refresh();

  //   gsap.registerPlugin(ScrollTrigger);


  //   // Using Locomotive Scroll
    
  //   const locoScroll = new LocomotiveScroll({
  //     el: document.querySelector(".App"),
  //     smooth: true
  //   });
  //   // each time Locomotive Scroll updates, tell ScrollTrigger to update too (sync positioning)
  //   locoScroll.on("scroll", ScrollTrigger.update);
    
  //   // tell ScrollTrigger to use these proxy methods for the ".smooth-scroll" element since Locomotive Scroll is hijacking things
  //   ScrollTrigger.scrollerProxy(".wwerapproach-section", {
  //     scrollTop(value) {
  //       return arguments.length ? locoScroll.scrollTo(value, 0, 0) : locoScroll.scroll.instance.scroll.y;
  //     }, // we don't have to define a scrollLeft because we're only scrolling vertically.
  //     getBoundingClientRect() {
  //       return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
  //     }
  //   });
    
    

  //   return () => {
  //     locoScroll.destroy();
  //   }
  // },[]);

  return (  
    <div className="App">
    <ScrollToTop />
    {/* <div id="main" style={{overflowY: 'hidden'}}> */}
      <Routes>
          <Route path='/' element={ <Home /> } exact />
          <Route path='/who-we-are' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are/#aboutus' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are/#ourvalues' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are/#ourteam' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are/#atechpartners' element={ <WhoWrAre /> } exact />
          <Route path='/who-we-are2' element={ <WhoWrAre2 /> } exact />
          <Route path='/ventures' element={ <Ventures /> } exact />
          <Route path='/what-we-do' element={ <CloudApplication /> } exact />
          <Route path='/cloud-and-application' element={ <CloudApplication /> } exact />
          <Route path='/data-and-ai' element={ <DataAI /> } exact />
          <Route path='/digital-innovation' element={ <DigitalInnovation /> } exact />
          <Route path='/intelligent-automation' element={ <IntelligentAutomation /> } exact />
          <Route path='/join-us' element={ <JoinUs /> } exact />
          <Route path='/contact-us' element={ <ContactUs /> } exact />
          <Route path='/terms-and-condition' element={ <Terms /> } exact />
          <Route path='/privacy-policy' element={ <PrivacyPolicy /> } exact />
        </Routes>
    {/* </ScrollToTop> */}

    {/* </div> */}
      {/* <Footer />      */}
    </div>
  );
}

export default App;
