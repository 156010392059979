import React from 'react'
import './wwer-about.css';

import wwerabout from '../images/wwer-about.webp'



const WwerAbout = () => {
  return (
    <>
    <section className='wwerabout-section' id="aboutus">
        <div className='container'>
            <div className='row align-items-center  g-5'>
                <div className='col-md-9  order-m-1'>
                    <div className='bl-green'>
                    <div className='text-box '>
                        <h1 className='desktop'><span>A Uniquely</span><br />Different Innovation Company</h1>
                        <h1 className='mobile tablet'><span>A Uniquely</span> Different Innovation Company</h1>
                    </div>
                         {/* <p>We exist to equip businesses with cutting-edge capabilities to ensure they thrive in a tech-driven era. With an AI-first approach at our core, we optimize talent, delivery, and investments, slashing time and costs while elevating efficiency across the board.</p> */}
                    </div>
                    <p>ConceptVines is a Global Consulting & Technology Services Company. We exist to equip businesses with cutting-edge capabilities to ensure they thrive in a tech-driven era.</p>
                    <p>With an <span>AI-first approach</span> at our core, we appropriately leverage AI and other transformational technologies to support our customers in modernizing their existing technology platforms while also innovating new business models and products.</p>
                </div>
                <div className='col-md-3 text-center'>
                    <img src={wwerabout} alt='' />
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default WwerAbout
