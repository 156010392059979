import React from 'react'

import Menu from '../components/Menu'
import Header2 from '../components/Header2';
import Datadriven from '../components/Datadriven';
import WwerContact from '../components/WwerContact';
import Footer from '../components/Footer';
// import HomeReadiness from '../components/HomeReadiness';

import AboutSection from '../components/AboutSection';

import aboutimg from '../images/about-dataai.webp'
import { Helmet } from 'react-helmet';

import bgvideo from "../video/Data and AI_HD.mp4";
import bgvideomb from "../video/mobile/Data and AI_HD_mb.mp4";

const DataAI = () => {
    // Header content 
    const heading = `Data Excellence: Where <br /> <span>Precision</span> meets <span>Potential</span>`;
    // const btntext = "Unleash data";
    const btntext = "Talk to us";
    const btnlink = "#contact";
    
    // about section content
    const abouttext = `<p>We believe that <span>data</span> holds the key to unlocking <span>true business resilience and excellence.</span> We transform data into a <span>powerful catalyst for success</span>, paving the way to a future of growth and adaptability through our custom-created, industry playbooks and outcome-driven implementation strategies.</p>`;

return (
    <>
    <Helmet>
      <title>Data and Artificial Intelligence</title>
      <meta property="og:title" content="Data and AI" />
      <meta name='og:description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div>
        <Menu />
        <Header2 class="dataai-bg" bgvideomb={bgvideomb} bgvideo={bgvideo} heading={heading} btntext={btntext} btnlink={btnlink} />
        <AboutSection img={aboutimg}  text={abouttext} />
        <Datadriven /> 
        {/* <HomeReadiness /> */}
        <WwerContact />
        <Footer />      
    </div>
    </>
  )
}

export default DataAI
