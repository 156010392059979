import React from 'react'
import { Row } from 'react-bootstrap'
import './addresses.css'
import addressbg from '../video/Contact2_HD.mp4';

// import addressmb from '../video/mobile/Contact2_HD_mb.mp4';


const Addresses = () => {

  const addresses = [
   {
    id: 1,
    line1 : "New York City,<br><span>US Global Headquarters</span>",
    line2 : "400 Park Avenue - 6th Floor,<br /> New York, NY 10022", 
    line3 : "Phone: <a href='tel:+1(212)2544876'>+1 (212) 254-4876</a>" 
   }, 
   {
    id: 2,
    line1 : "London,<br> <span>UK</span>",
    line2 : "Level 39, One Canada Square,<br /> Canary Wharf, London E14 5AB", 
    line3 : "Phone: <a href='tel:+447903363178'>+44 7903363178</a>" 
   },
  //  {
  //   id: 3,
  //   line1 : "San Francisco,<br><span> US</span>",
  //   line2 : "5201 Great America Parkway, Suite 320, Santa Clara, California, USA 95054", 
  //   line3 : "Phone: <a href='tel:+1415692-0055'>+1 (415) 692-0055</a>" 
  //  },
  //  {
  //   id: 4,
  //   line1 : "Paris,<br> <span>France</span>",
  //   line2 : "14 Place Des Impressionnistes, 92500, Rueil Malmaison, France", 
  //   line3 : "Phone: <a href='+33685090284'>+33 6 85 09 02 84</a>" 
  //  },
   {
    id: 5,
    line1 : "Colombo, <br><span>Srilanka</span>",
    line2 : "94/14 Borella Road, Depanama,<br /> Pannipitiya, Sri Lanka <br /> ", 
    line3 : "Phone: <a href='tel:+94778608990'>+94 77 860 8990</a>" 
   },
   {
    id: 5,
    line1 : "Chennai, <br><span>India</span>",
    line2 : "JSR Castle, 2nd Floor,<br /> 17/8 VijayaRaghava Road, T Nagar, <br /> Chennai, Tamil Nadu 600017", 
    line3 : "Phone: <a href='tel:+919940095410'>+91 9940095410</a>" 
   },
    


  //  {
  //   id: 6,
  //   line1 : "Manila, <br><span>Philippines</span>",
  //   line2 : "28F & Penthouse World Plaza, 5th Avenue Bonifacio Global City, Taguig, Metro Manila", 
  //   line3 : "Phone: <a href='tel:+447903363178'>+44 7903 363178</a>" 
  //  }
  
   
  ]

  return (
    <section className='section-addresses'>
       <video id="background-video" className='desktop' title='bg-video' playsInline  loop={true} muted={true} autoPlay={true} >
            <source src={addressbg} type="video/mp4" />
            {/* <source src={bgvideo} type="video/ogg" /> */}
            Your browser does not support the video tag.
        </video>
        <div className='container'>

            <div className='heading-box py-0 py-lg-5'>
                <h1>Our global footprint of   <span>driving excellence</span></h1>
            </div>

            <Row>           
                <div className='col-md-6 mobile'>
                  <video className='mobile-video' playsInline  loop={true} muted={true} autoPlay={true} >
                      <source src={addressbg} type="video/mp4" />
                      {/* <source src={bgvideo} type="video/ogg" /> */}
                      Your browser does not support the video tag.
                  </video>            
                </div>
                <div className='col-md-12 tablet'>
                  <video className='mobile-video' playsInline  loop={true} muted={true} autoPlay={true} >
                      <source src={addressbg} type="video/mp4" />
                      {/* <source src={bgvideo} type="video/ogg" /> */}
                      Your browser does not support the video tag.
                  </video>            
                </div>
                <div className='col-md-6 offset-6'>
                  <div className='addresses'>

                    {
                      addresses.map((add , index) =>{
                        return(
                        <div className='address' key={index}>
                          <h4 dangerouslySetInnerHTML={{__html:add.line1}}></h4>
                          <h5 dangerouslySetInnerHTML={{__html:add.line2}}></h5>
                          <p dangerouslySetInnerHTML={{__html:add.line3}}></p>
                        </div>
  
                        );
                      })
                    }

{/*                   
                      <div className='address'>
                        <h4>San Francisco, <br />US</h4>
                        <h5>5201 Great America Parkway,<br />Suite 320, Santa Clara, <br />California, USA 95054</h5>
                        <p><a href="tel:+14156920055">Phone: +1 (415) 692-0055</a></p>
                      </div>
                  
                      <div className='address'>
                        <h4>Paris, <br />France</h4>
                        <h5>14 Place Des Impressionnistes, <br />92500, Rueil Malmaison, <br />France</h5>
                        <p><a href="tel:+33685090284">Phone: +33 6 85 09 02 84</a></p>
                      </div>
                  
                      <div className='address'>
                        <h4>Colombo,<br />Srilanka</h4>
                        <h5>94/14 Borella Road, <br />Depanama, Pannipitiya. <br />Sri Lanka</h5>
                        <p><a href="tel:+94778608990">Phone: +94 77 860 8990</a></p>
                      </div>
                  
                      <div className='address'>
                        <h4>Manila,<br />Philippines</h4>
                        <h5>28F & Penthouse World Plaza, <br />5th Avenue Bonifacio Global City, <br />Taguig, Metro Manila</h5>
                        <p><a href="tel:+639052992358">Phone: +63 905 299 2358</a></p>
                      </div>
                   */}
                  </div>

                </div>
            </Row>
       </div>
    </section> 
  )
}

export default Addresses
