import React from 'react'
import { Row } from 'react-bootstrap'
// import { NavLink } from 'react-router-dom'

import './beyondthedesk.css'

// import img1 from '../images/build-the-future.webp'
// import img2 from '../images/where-everyone-belongs.webp';
// import img3 from '../images/move-fast.webp';
// import img4 from '../images/to-build.webp';
// import img5 from '../images/we-win.webp';


import imgs1 from '../images/take-the-lead3.webp'
import imgs2 from '../images/where-everyone-belongs3.webp';
import imgs3 from '../images/move-fast3.webp';
import imgs4 from '../images/to-build3.webp';
import imgs5 from '../images/we-win3.webp';


const BeyondTheDesk = () => {

    return (
    <>
    <section className="beyondthedesk-section">
        <div className="container">
            <div className="main-box">
                    <h1>
                    Beyond the desk,<br /> life at <span>ConceptVines</span> 
                    </h1>
            </div>
        </div>

        {/* <div className="container py-5">
            <Row className="g-5">   
                <div className="col-md-7 text-center">
                    <div className='img-box'><img src={img1} alt="" /></div>
                </div>
                <div className="col-md-5">
                    <div className="text-side pe-5  ">
                        <h3>Take the lead, build the future</h3>
                        <p>We don't just keep pace with innovation, we set the pace. We believe in staying on tech's bleeding edge alongside pioneering startups.</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5 mt-3">   
            <div className="col-md-5">
                <div className="text-side">
                    <h3>Where everyone belongs</h3>
                    <p>Where everyone belongs We actively work towards building an inclusive environment where ideas thrive and people shine.</p>    
                </div>
                </div>
                <div className="col-md-7 text-center">
                    <div className='img-box'><img src={img2} alt="" /></div>
                </div>
            </Row>
            <Row className="g-5 mt-3">   
                <div className="col-md-7 text-center">
                     <div className='img-box'><img src={img3} alt="" /></div>
                 </div>
                <div className="col-md-5">
                    <div className="text-side pe-5  ">
                        <h3>Move fast, learn faster</h3>
                        <p>We believe in the power of rapid action, embracing change, and learning from every experience to drive innovation and excellence.</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5 mt-3">   
            <div className="col-md-5">
                    <div className="text-side">
                        <h3>Where everyone belongs</h3>
                        <p>We actively work towards building an inclusive environment where ideas thrive and people shine.</p>
                    </div>
                </div>
                <div className="col-md-7 text-center">
                     <div className='img-box'><img src={img4} alt="" /></div>
                </div>
            </Row>
            <Row className="g-5 mt-3">   
                <div className="col-md-7 text-center">
                      <div className='img-box'><img src={img5} alt="" /></div>
                </div>
                <div className="col-md-5">
                    <div className="text-side pe-5  ">
                        <h3>Move fast, learn faster</h3>
                        <p>We believe in the power of rapid action, embracing change, and learning from every experience to drive innovation and excellence.</p>
                    </div>
                </div>
            </Row>

        </div>
        */}
        <div className="container py-5 mx-auto px-5 ">
            <Row className="g-5">   
                <div className="col-md-4 text-center">
                    <div className='img-box'><img src={imgs1} alt="" /></div>
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5  ">
                        <h3>Take the lead, build the future</h3>
                        <p>We don't just keep pace with innovation, we set the pace. We believe in staying on tech's bleeding edge alongside pioneering startups.</p>
                    </div>
                </div>                
        
            </Row>
            <Row className="g-5 mt-3">   
                <div className="col-md-8 order-m-1">
                    <div className="text-side">
                        <h3>Where everyone belongs</h3>
                        <p>We actively work towards building an inclusive environment where ideas thrive and people shine.</p>    
                    </div>
                </div>
                <div className="col-md-4 text-center">
                    <div className='img-box'><img src={imgs2} alt="" /></div>
                </div>
            </Row>
            <Row className="g-5 mt-3">   
                <div className="col-md-4 text-center">
                     <div className='img-box'><img src={imgs3} alt="" /></div>
                 </div>
                <div className="col-md-8">
                    <div className="text-side pe-5  ">
                        <h3>Move fast, learn faster</h3>
                        <p>We believe in the power of rapid action, embracing change, and learning from every experience to drive innovation and excellence.</p>
                    </div>
                </div>
            </Row>
            <Row className="g-5 mt-3">   
                <div className="col-md-8 order-m-1">
                    <div className="text-side">
                        <h3>To build, you need to be fearless </h3>
                        <p> We foster a culture that encourages our team to fearlessly take on innovative challenges.</p>
                    </div>
                </div>
                <div className="col-md-4 text-center">
                      <div className='img-box'><img src={imgs4} alt="" /></div>
                </div>
            </Row>
            <Row className="g-5 mt-3">   
                <div className="col-md-4 text-center">
                      <div className='img-box'><img src={imgs5} alt="" /></div>
                </div>
                <div className="col-md-8">
                    <div className="text-side pe-5  ">
                        <h3>We win when our customers succeed</h3>
                        <p>Customer obsession isn't just a value; it's our way of life. We are are passionate about understanding, anticipating, and exceeding the needs and expectations of our customers.</p>
                    </div>
                </div>
            </Row>
        </div>
    
    </section>
    </>
  )
}

export default BeyondTheDesk
