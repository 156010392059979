import React from 'react'

import { Helmet } from 'react-helmet';
import Menu from '../components/Menu'
import Header2 from '../components/Header2';
// import Datadriven from '../components/Datadriven';
import WwerContact from '../components/WwerContact';
import Footer from '../components/Footer';
// import HomeReadiness from '../components/HomeReadiness';

import AboutSection from '../components/AboutSection';
import aboutimg from '../images/about-ventures.webp'
import FundThesis from '../components/FundThesis';
import InvestmentEthos from '../components/InvestmentEthos';

import bgvideo from "../video/Ventures_HD.mp4";
import bgvideomb from "../video/mobile/Ventures_HD_mb.mp4";
import VentureDisruptors from '../components/VentureDisruptors';

const Ventures = () => {

    // Header content 
    const heading = `Transforming Ideas into<br /> <span>Thriving</span> ventures`;
    const btntext = "Talk to us";
    const btnlink = "/contact-us#contact";
    
    // about section content
    // const abouttext = `<p>At our core is the <span>unique Venture Engineering model, transforming Open Innovation</span> into a catalyst for business success. Inspired by the Japanese concept 'Ikigai,' we identify disruptive startups and visionary founders through our own fund and extensive network of VC and PE firms, including our investor Matrix Private Capital Group.</p>`;
    const abouttext = `<p>At our core is the <span>unique Venture Engineering model, transforming Open Innovation</span> into a catalyst for business success. Inspired by the Japanese concept 'Ikigai,' we identify disruptive startups and visionary founders through our own direct investments & partnerships as well as from our broad network of other VC and PE firms.</p>`;
    
 
    return (
        <>
        <Helmet>
          <title>Ventures</title>
          <meta property="og:title" content="Ventures" />
          <meta name='og:description' content=''/>
          <meta name='keywords' content=''/>
        </Helmet>
        <div>
            <Menu />
            <Header2 class="ventures-bg" bgvideo={bgvideo} bgvideomb={bgvideomb} heading={heading} btntext={btntext} btnlink={btnlink} />
            <AboutSection class="about-venture" img={aboutimg}  text={abouttext} />
            <InvestmentEthos />
            <FundThesis /> 
            <VentureDisruptors />
            {/* <HomeReadiness /> */}
            <WwerContact />
            <Footer />      
        </div> 
        </>
    
  )
}



export default Ventures
