import React from 'react'
import Menu from '../components/Menu'
import Header2 from '../components/Header2'
import WwerAbout from '../components/WwerAbout'
// import Animation from '../components/Animation'
import WwerAifirst from '../components/WwerAifirst'
import WwerInnovators from '../components/WwerInnovators'
import WwerContact from '../components/WwerContact'
import Footer from '../components/Footer'
// import WwerApproach from '../components/WwerApproach'
import { Helmet } from 'react-helmet'

import bgvideo from "../video/Whoweare_HD.mp4";
import bgvideomb from "../video/mobile/Whoweare_HD_mb.mp4";
import PoweredBy from '../components/PoweredBy'
import AIFPrinciples from '../components/AIFPrinciples'
import AtechPartners from '../components/AtechPartners'

const WhoWrAre = () => {

  const heading = `Your <span>bridge</span> to<br> 'What can be'`;
  const btntext = "Talk to us";
  const btnlink = "/contact-us#contact";

  return (
    <>
    <Helmet>
      <title>Who We are</title>
      <meta property="og:title" content="Who We are" />
      <meta name='og:description' content='ConceptVines offers Consulting and Product Engineering Services focused on disruptive technologies & fueled by progressive startups.'/>
      <meta name='keywords' content=''/>
    </Helmet>
    <div>
        <Menu />
        <Header2 class="header-whowr" bgvideomb={bgvideomb} bgvideo={bgvideo}  heading={heading} btntext={btntext} btnlink={btnlink} />
        <WwerAbout />
        <PoweredBy />
        {/* <Animation /> */}
        <AIFPrinciples />
        <WwerAifirst />
        <WwerInnovators />
        <AtechPartners />
        <WwerContact />
        <Footer />
    </div>
  </>
    
  )
}

export default WhoWrAre
