import React from 'react'
import { Row } from 'react-bootstrap'

import img1 from '../images/Speed.webp'
import img2 from '../images/Safety.webp'
import img3 from '../images/Innovation.webp'
import img4 from '../images/Integrity.webp'
import img5 from '../images/Certainity.webp'

import './wwer-aifirst.css'
// import bg from '../video/Whoweare_2.mp4';


const WwerAifirst = () => {
  return (

    <>
    {/* <section className='wweraifirst-section'>
        <div className='container'>
            <div className='main-box'>
                    <h1>
                        Genesis of our AI-first<br />
                        revolution
                    </h1>
            </div>     
        </div>           
        
            <div className='aifirt-bg'>
                <video className="background-video" title='bg-video' playsInline  loop={true} muted={true} autoPlay={true} >
                    <source src={bg} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className='container'>
                    <div className='img-box'>
                        <p>
                        We are a seasoned squad of tech industry experts, renowned for spearheading large-scale modernization and innovation initiatives leveraging cutting-edge technologies for numerous Fortune 500 businesses. Over their decades in technology services, our founders identified that there was scope for truly empowering clients to adopt advanced technologies. This gap spurred the founding of ConceptVines.  "Concept" represents planting the seeds of new ideas, and "Vines" symbolizes rapid growth. <br /><br />
                        Headquartered in the US, we’re a new-age, AI-first, asset-led product engineering firm dedicated to catapulting businesses into self-reliance by wielding leading-edge technology. We devise outcome-focused solutions for sectors being massively disrupted by technology, such as BFSI, Manufacturing, Healthcare, and Gaming. 
                        </p>
                    </div>
                </div>    
                <p>We don't just engineer high-tech solutions. We architect the tech-dominated future.</p>

            </div>        
               
    </section> */}

    
    <section className='wwerrooted-section' id='ourvalues'>
        <div className='container py-0 py-md-5'>
            <div className='bl-green'>
                    <h2>Our Values</h2>
                <div className='text-box'>
                    <p>Outside of our skills, these core values of our company are critical to both our own and our customer’s success</p>
                    {/* <h2 className='desktop'>Rooted in innovation, nurtured by <br />
                    <span>values</span></h2>
                    <h2 className='mobile'>Rooted in innovation, nurtured by <span> values</span></h2> */}
                </div>
            </div>
        </div>

        <div className='container'>
            <Row className='align-items-center g-5'>   
                <div className='col-md-6 pe-5 order-m-1'>
                    <h3>People</h3>
                    <p>We treat our people exceptionally well by providing both intrinsic and extrinsic benefits to help them remain happy both personally and professionally. We promote a highly empowering, safe and entrepreneurial work environment which helps us attract and retain top industry talent.</p>
                </div>
                <div className='col-md-6 text-center'>
                    <img src={img1} alt='' />
                </div>
            </Row>
            <Row className='g-5'>
                <div className='col-md-6 text-center'>
                    <img src={img2} alt='' />
                </div>   
                <div className='col-md-6 ps-5'>
                    <h3>Clients</h3>
                    <p>We walk through walls for our clients. We seek to provide a customer experience which is unique, caring, memorable and impactful. As a specialized company focused on strategy and innovation, it is paramount that we align tightly to our client’s teams and strategic objectives and be viewed as a trusted partner and an indispensable part of their organization.</p>
                </div>
                
            </Row>
 
            <Row className='g-5'>   
                <div className='col-md-6 pe-5 order-m-1'>
                    <h3>Innovation</h3>
                    <p>We are always innovating and looking to stay on the ultimate bleeding edge of technology and the establishment of new dynamic business models. We seek to leave repeated footprints of new technology platforms to help our clients truly transform their business. To reframe their purpose. To create step-change 10x outcomes. To actively help our startup companies grow successfully. To help our established enterprise clients turn the tables on digital disrupters while creating their own market disruption.</p>
                </div>
                <div className='col-md-6 text-center'>
                    <img src={img3} alt='' />
                </div>
            </Row>

            <Row className='g-5'>
                <div className='col-md-6 text-center'>
                    <img src={img4} alt='' />
                </div>   
                <div className='col-md-6 ps-5'>
                    <h3>Integrity</h3>
                    <p>We operate as a firm with the highest degree of integrity and business ethics with no exceptions.</p>
                </div>
                
            </Row>

            <Row className='g-5'>   
                <div className='col-md-6 pe-5 order-m-1'>
                    <h3>Social responsibility</h3>
                    <p>We believe in and invest heavily in social responsibility and actively support and promote social causes and impact across our business and investments. We promote diversity in our global workforce. A core part of our solution and portfolio focus is targeted at social areas including Financial Wellness, Health and Wellness and Sustainability.</p>
                </div>
                <div className='col-md-6 text-center'>
                    <img src={img5}  alt='' />
                </div>
            </Row>

        </div>
    </section>
    </>
  )
}

export default WwerAifirst
