import React from 'react'

import Menu from '../components/Menu'
// import CaHeader from '../components/CaHeader';
import AboutCaSection from '../components/AboutCaSection'
import CloudDriven from '../components/CloudDriven';
import WwerContact from '../components/WwerContact';
import Footer from '../components/Footer';
// import HomeReadiness from '../components/HomeReadiness';
import Header2 from '../components/Header2';
import { Helmet } from 'react-helmet';

import bgvideo from "../video/Cloud and apps_HD.mp4";
import bgvideomb from "../video/mobile/Cloud and apps_HD_mb.mp4";


const CloudApplication = () => {
const heading = `Architecting the future. <br /> Powered by <span>Cloud</span>`;
// const btntext = "Transform today";
const btntext = "Talk to us";
const btnlink = "#contact";



return (
  <>
     <Helmet>
      <title>Cloud and Applications</title>
      <meta property="og:title" content="Cloud and Applications" />
      <meta name='og:description' content=''/>
      <meta name='keywords' content=''/>
    </Helmet>


    <div>
        <Menu />
        {/* <CaHeader /> */}
        <Header2 class="cloudai-bg" bgvideomb={bgvideomb} bgvideo={bgvideo} heading={heading} btntext={btntext} btnlink={btnlink} />
        <AboutCaSection />       
        <CloudDriven /> 
        {/* <HomeReadiness /> */}
        <WwerContact />
        <Footer />
    </div>
  </>
  )
}

export default CloudApplication
